import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import vaultReducer from './reducers/vault';
import rewardsReducer from './reducers/rewards';
import cratesMintSlice from "./reducers/cratemint";

const reducers = combineReducers({
  vault: vaultReducer,
  rewards: rewardsReducer,
  cratemint: cratesMintSlice
});
const persistConfig = {
  key: 'outerverse_persist',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export default store;
