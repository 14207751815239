import { useNavigate, Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import gsap from "gsap";
import "./header.css";

export const Header = (props) => {
  const { goToSlide, doAnim, isActive } = props;
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (doAnim) {
      const tl = gsap.timeline();
      tl.fromTo(document.querySelectorAll(".nav-link"),
        {
          opacity: 0,
          y: -100,
        },
        {
          duration: 1,
          opacity: 1,
          y: 0,
          stagger: 0.2,
          delay: 0.5,
        });
      tl.play();
    }
  }, []);

  return (
    <div id="header" className={ isActive ? 'active' : 0 }>
      <div className="container">
        <div className="nav-links">
          <span className="nav-link" onClick={() => doAnim ? goToSlide(0) : navigate('/#home')}>
            HOME
          </span>
          <span className="nav-link" onClick={() => doAnim ? goToSlide(1) : navigate('/#universe')}>
            UNIVERSES
          </span>
          <span className="nav-link" onClick={() => doAnim ? goToSlide(2) : navigate('/#roadmap')}>
            ROADMAP
          </span>
          <span className="nav-link" onClick={() => navigate('/crates')}>
            CRATE MINT
          </span>
          <Link to="https://marketplace.theouterverse.io" target="_blank" rel="noopener noreferrer" className="nav-link">
            MARKETPLACE
          </Link>
        </div>
      </div>
    </div>
  );
};
