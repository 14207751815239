import { createSlice } from '@reduxjs/toolkit';

const initialVaultState = {
    mbh: {
        failedMsg: null,
        rewardAmount: 0,
        paidAmount: 0,
        directAmount: 0,
        directPaidAmount: 0,
        watchAmount: 0,
        watchPaidAmount: 0,
        affiliateAmount: 0,
        affiliatePaidAmount: 0,
        iconicDirectAmount: 0,
        iconicDirectPaidAmount: 0,
        iconicAffiliateAmount: 0,
        iconicAffiliatePaidAmount: 0
    },
    mla: {
        failedMsg: null,
        rewardAmount: 0,
        paidAmount: 0,
        directAmount: 0,
        directPaidAmount: 0,
        affiliateAmount: 0,
        affiliatePaidAmount: 0,
    },
    huntress: {
        failedMsg: null,
        rewardAmount: 0,
        paidAmount: 0,
        directAmount: 0,
        directPaidAmount: 0,
        affiliateAmount: 0,
        affiliatePaidAmount: 0,
    }
};

export const rewardsSlice = createSlice({
    name: 'rewards',
    initialState: initialVaultState,
    reducers: {
        setMBHRewards: (state, action) => {
            state.mbh.failedMsg = null;
            state.mbh.rewardAmount = action.payload.rewardAmount;
            state.mbh.paidAmount = action.payload.paidAmount;
            state.mbh.directAmount = action.payload.directAmount;
            state.mbh.directPaidAmount = action.payload.directPaidAmount;
            state.mbh.watchAmount = action.payload.watchRewardAmount;
            state.mbh.watchPaidAmount = action.payload.watchRewardPaidAmount;
            state.mbh.affiliateAmount = action.payload.affiliateAmount;
            state.mbh.affiliatePaidAmount = action.payload.affiliatePaidAmount;
            state.mbh.iconicDirectAmount = action.payload.iconicDirectAmount;
            state.mbh.iconicDirectPaidAmount = action.payload.iconicDirectPaidAmount;
            state.mbh.iconicAffiliateAmount = action.payload.iconicAffiliateAmount;
            state.mbh.iconicAffiliatePaidAmount = action.payload.iconicAffiliatePaidAmount;
        },
        checkMBHRewardsFailed: (state, action) => {
            state.mbh.failedMsg = action.payload?.failedMsg;
            state.mbh.rewardAmount = action.payload?.rewardAmount > 0 ? action.payload.rewardAmount : 0;
            state.mbh.paidAmount = action.payload?.paidAmount > 0 ? action.payload.paidAmount : 0;
            state.mbh.directAmount = action.payload?.directAmount ? action.payload.directAmount : 0;
            state.mbh.directPaidAmount = action.payload?.directPaidAmount > 0 ? action.payload.directPaidAmount : 0;
            state.mbh.watchAmount = action.payload?.watchRewardAmount > 0 ? action.payload.watchRewardAmount : 0;
            state.mbh.watchPaidAmount = action.payload?.watchRewardPaidAmount > 0 ? action.payload.watchRewardPaidAmount : 0;
            state.mbh.affiliateAmount = action.payload?.affiliateAmount > 0 ? action.payload.affiliateAmount : 0;
            state.mbh.affiliatePaidAmount = action.payload?.affiliatePaidAmount > 0 ? action.payload.affiliatePaidAmount : 0;
            state.mbh.iconicDirectAmount = action.payload?.iconicDirectAmount > 0 ? action.payload.iconicDirectAmount : 0;
            state.mbh.iconicDirectPaidAmount = action.payload?.iconicDirectPaidAmount > 0 ? action.payload.iconicDirectPaidAmount : 0;
            state.mbh.iconicAffiliateAmount = action.payload?.iconicAffiliateAmount > 0 ? action.payload.iconicAffiliateAmount : 0;
            state.mbh.iconicAffiliatePaidAmount = action.payload?.iconicAffiliatePaidAmount > 0 ? action.payload.iconicAffiliatePaidAmount : 0;
        },
        setMLARewards: (state, action) => {
            state.mla.failedMsg = null;
            state.mla.rewardAmount = action.payload.rewardAmount;
            state.mla.paidAmount = action.payload.paidAmount;
            state.mla.directAmount = action.payload.directAmount;
            state.mla.directPaidAmount = action.payload.directPaidAmount;
            state.mla.affiliateAmount = action.payload.affiliateAmount;
            state.mla.affiliatePaidAmount = action.payload.affiliatePaidAmount;
        },
        checkMLARewardsFailed: (state, action) => {
            state.mla.failedMsg = action.payload?.failedMsg;
            state.mla.rewardAmount = action.payload?.rewardAmount > 0 ? action.payload.rewardAmount : 0;
            state.mla.paidAmount = action.payload?.paidAmount > 0 ? action.payload.paidAmount : 0;
            state.mla.directAmount = action.payload?.directAmount > 0 ? action.payload.directAmount : 0;
            state.mla.directPaidAmount = action.payload?.directPaidAmount > 0 ? action.payload.directPaidAmount : 0;
            state.mla.affiliateAmount = action.payload?.affiliateAmount > 0 ? action.payload.affiliateAmount : 0;
            state.mla.affiliatePaidAmount = action.payload?.affiliatePaidAmount > 0 ? action.payload.affiliatePaidAmount : 0;
        },
        setHuntressRewards: (state, action) => {
            state.huntress.failedMsg = null;
            state.huntress.rewardAmount = action.payload.rewardAmount;
            state.huntress.paidAmount = action.payload.paidAmount;
            state.huntress.directAmount = action.payload.directAmount;
            state.huntress.directPaidAmount = action.payload.directPaidAmount;
            state.huntress.affiliateAmount = action.payload.affiliateAmount;
            state.huntress.affiliatePaidAmount = action.payload.affiliatePaidAmount;
        },
        checkHuntressRewardsFailed: (state, action) => {
            state.huntress.failedMsg = action.payload?.failedMsg;
            state.huntress.rewardAmount = action.payload?.rewardAmount > 0 ? action.payload.rewardAmount : 0;
            state.huntress.paidAmount = action.payload?.paidAmount > 0 ? action.payload.paidAmount : 0;
            state.huntress.directAmount = action.payload?.directAmount > 0 ? action.payload.directAmount : 0;
            state.huntress.directPaidAmount = action.payload?.directPaidAmount > 0 ? action.payload.directPaidAmount : 0;
            state.huntress.affiliateAmount = action.payload?.affiliateAmount > 0 ? action.payload.affiliateAmount : 0;
            state.huntress.affiliatePaidAmount = action.payload?.affiliatePaidAmount > 0 ? action.payload.affiliatePaidAmount : 0;
        }
    }
});

export const { setMBHRewards, checkMBHRewardsFailed, setMLARewards, checkMLARewardsFailed, setHuntressRewards, checkHuntressRewardsFailed } =
    rewardsSlice.actions;

export default rewardsSlice.reducer;