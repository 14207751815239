import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination } from "swiper/modules";

import mbh from "../../assets/images/projects/metabountyhunters.png";
import mbhu from "../../assets/images/projects/metabountyhuntresses.png";
import mla from "../../assets/images/projects/metalabsagents.png";
import mbhIconic from "../../assets/images/projects/metabounty3Diconics.png";
import mbb from "../../assets/images/projects/metabountybabies.png";
import mbc from "../../assets/images/projects/metabountycrates.gif";

import planet1 from "../../assets/images/projects/planet1.png";
import planet2 from "../../assets/images/projects/planet2.png";
import planet3 from "../../assets/images/projects/planet3.png";
import planet4 from "../../assets/images/projects/planet4.png";
import planet5 from "../../assets/images/projects/planet5.png";
import planet6 from "../../assets/images/projects/planet6.png";

import "./universe.css";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export const Universe = () => {
  const [planetClasses, setPlanetClasses] = useState([
    "planet1",
    "planet2",
    "planet3",
    "planet4",
    "planet5",
    "planet6",
  ]);

  const container = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const effect_items = document.querySelectorAll(".verify-btn");
    const hover = [];
    effect_items.forEach((item, index) => {
      hover[index] = gsap.to(item, {
        boxShadow: "0px 0px 20px #FF5EBB",
        duration: 0.5,
        paused: true,
      });
    });
    effect_items.forEach((item, index) => {
      item.addEventListener("mouseenter", () => hover[index].play());
    });
    effect_items.forEach((item, index) => {
      item.addEventListener("mouseleave", () => hover[index].reverse());
    });
  }, []);

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container.current.querySelector("div"),
        scroller: ".scroller",
      },
    });

    tl.fromTo(
      document.querySelectorAll(".main-universe span"),
      { opacity: 0 },
      { duration: 1, ease: "power4.inOut", opacity: 1 },
      0.5
    );
    tl.fromTo(
      document.querySelectorAll(".main-universe h1"),
      { opacity: 0 },
      { duration: 1, ease: "power4.inOut", opacity: 1, delay: 0.3 },
      0.5
    );
    tl.fromTo(
      document.querySelectorAll(".planet1, .planet2, .planet3, .planet4, .planet5, .planet6"),
      { opacity: 0 },
      { duration: 1, ease: "power4.inOut", opacity: 1, stagger: 0.2 },
      0.5
    );
    tl.fromTo(
      document.querySelectorAll(".project-swiper"),
      { opacity: 0 },
      { duration: 1, ease: "power4.inOut", opacity: 1, delay: 1 },
      1.5
    );
  }, []);

  return (
    <div className="section universe" id="universe" ref={container}>
      <div className="container h-100 justify-content-center">
        <div className="main-universe">
          <span>GALACTIC</span>
          <h1>MEMBERS</h1>
        </div>
        <Swiper
          speed={1000}
          effect={"fade"}
          navigation={true}
          pagination={{ clickable: true }}
          modules={[EffectFade, Navigation, Pagination]}
          onSlideChange={(e) => {
            if (e.activeIndex > e.previousIndex)
              setPlanetClasses((prev) => [prev.at(-1), ...prev.slice(0, -1)]);
            else setPlanetClasses(([first, ...rest]) => [...rest, first]);
          }}
          className="project-swiper"
        >
          <SwiperSlide>
            <div className="project-container">
              <img src={mbh} />
              <span>META BOUNTY<br />HUNTERS</span>
              <button className="verify-btn" onClick={() => navigate('/vault/mbh')}>VERIFY WALLET</button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="project-container">
              <img src={mbhu} />
              <span>META BOUNTY<br />HUNTRESSES</span>
              <button className="verify-btn" onClick={() => navigate('/vault/huntress')}>VERIFY WALLET</button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="project-container">
              <img src={mla} />
              <span>FIELD/SECRET<br />AGENTS</span>
              <button className="verify-btn" onClick={() => navigate('/vault/mla')}>VERIFY WALLET</button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="project-container">
              <img src={mbhIconic} />
              <span>META BOUNTY HUNTERS<br />3D ICONICS</span>
              <button className="verify-btn" onClick={() => navigate('/vault/mbh')}>VERIFY WALLET</button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="project-container">
              <img src={mbb} />
              <span>META BOUNTY<br />BABIES</span>
              <button className="verify-btn" onClick={() => navigate('/vault/mbh')}>VERIFY WALLET</button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="project-container">
              <img src={mbc} />
              <span>META BOUNTY<br />CRATES</span>
              <button className="verify-btn" onClick={() => navigate('/vault/mbc')}>VERIFY WALLET</button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <img src={planet1} className={planetClasses[0]} />
      <img src={planet2} className={planetClasses[1]} />
      <img src={planet3} className={planetClasses[2]} />
      <img src={planet4} className={planetClasses[3]} />
      <img src={planet5} className={planetClasses[4]} />
      <img src={planet6} className={planetClasses[5]} />
    </div>
  );
};
