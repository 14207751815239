import gsap from "gsap";
import SplitType from "split-type";
import { useLayoutEffect } from "react";
import "./home.css";
import leftPlanet from "../../assets/images/home/left_planet.png";
import rightPlanet from "../../assets/images/home/right_planet.png";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export const Home = (props) => {
  const { goToSlide } = props;

  useLayoutEffect(() => {
    const tl = gsap.timeline();

    // planets animation
    tl.fromTo(
      document.querySelectorAll(".left-planet"),
      { x: "-100%", scale: 0 },
      { duration: 1.5, ease: "power4.inOut", x: 0, scale: 1, delay: 0.2 },
      0.5
    );
    tl.fromTo(
      document.querySelectorAll(".right-planet"),
      { x: "100%", scale: 0 },
      { duration: 1.5, ease: "power4.inOut", x: 0, scale: 1, delay: 0.2 },
      0.5
    );

    // texting animation
    const spanText = new SplitType(".main-square span", { types: "chars" });
    tl.fromTo(
      spanText.chars,
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        stagger: 0.05,
        duration: 0.5,
        ease: "power4.out",
      },
      1
    );

    const h1Text = new SplitType(".main-square h1", { types: "chars" });
    tl.fromTo(
      h1Text.chars,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.07,
        duration: 0.5,
        ease: "power4.out",
      },
      1.5
    );

    tl.fromTo(
      document.querySelectorAll(".main-square p"),
      { opacity: 0 },
      { duration: 1, ease: "power4.inOut", opacity: 1 },
      2.5
    );

    // scroll downer animation
    tl.fromTo(
      document.querySelectorAll(".scroll-downer"),
      { opacity: 0, y: -50 },
      { duration: 1, opacity: 1, y: 0 },
      3.5
    );

    const repeatTl = gsap.timeline({ repeat: -1 });
    repeatTl.fromTo(
      document.querySelectorAll(".scroll-downer"),
      { y: 0 },
      { duration: 1, y: 20 }
    );
    repeatTl.fromTo(
      document.querySelectorAll(".scroll-downer"),
      { y: 20 },
      { duration: 1, y: 0 },
      1
    );

    tl.play();
    repeatTl.play();
  }, []);

  return (
    <div className="section home" id="home">
      <img src={leftPlanet} className="left-planet" />
      <img src={rightPlanet} className="right-planet" />

      <div className="container d-flex h-100 justify-content-center">
        <div className="main-square">
          <span>WELCOME TO THE</span>
          <h1>OUTERVERSE</h1>
          <p>
            Become a part of an incredible journey toward a brighter future,
            where imagination and innovation reign supreme.
          </p>
        </div>
      </div>

      <div className="scroll-downer" onClick={() => goToSlide(1)}>
        <p>SCROLL DOWN</p>
        <div className="downer-line"></div>
      </div>
    </div>
  );
};
