import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from "react-router-dom";
import Web3 from "web3";
import { Animated } from "react-animated-css";
import Modal from "react-modal";
import Outerverse from "./pages/outerverse";
import Terms from "./pages/terms";
import Policy from "./pages/policy";
import MBHVault from "./pages/mbhvault";
import MLAVault from "./pages/mlavault";
import HuntressVault from "./pages/huntressvault";
import MBCVault from "./pages/mbcvault";
import CratePage from "./pages/crate";
import mbh_icon from "./assets/images/mbh_icon.png";
import './App.css';
import "animate.css";

function App() {
  const [metamaskConnected, setMetamaskConnnected] = useState(false);
  const [account, setAccount] = useState();
  const [networkId, setNetworkId] = useState();
  const [isMetamask, setIsMetamask] = useState(true);
  const [disclaimerModal, setDisclaimerModal] = useState(false);

  useEffect(() => {
    loadWeb3().then((data) => {
      if (data != false) {
        loadBlockchainData();
      }
    });
  });

  const loadWeb3 = async () => {
    try {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        // window.alert(
        //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
        // );
        setIsMetamask(false);
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadBlockchainData = async () => {
    try {
      const web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();
      setNetworkId(networkId);

      if (accounts.length == 0) {
        setMetamaskConnnected(false);
      } else {
        setMetamaskConnnected(true);
        setAccount(accounts[0]);
      }

      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) setAccount(accounts[0]);
        else setAccount();
      });
      window.ethereum.on("chainChanged", (chainId) => {
        setNetworkId(chainId);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setDisclaimerModal(false);
  };

  return (
    <>
      {/* {networkId != "0x1" && metamaskConnected && (
        <div className="network-err-msg">
          <h2>Please switch to main network</h2>
        </div>
      )} */}
      {!isMetamask && (
        <Animated
          animationIn="BounceInLeft"
          animationOut="fadeOut"
          animationInDelay={1000}
        >
          <div className="network-err-msg">
            <h2>
              Non-Ethereum browser detected. You should consider trying{" "}
              <a href="https://metamask.io/" target="_blank" rel="noreferrer">
                MetaMask!
              </a>
            </h2>
          </div>
        </Animated>
      )}
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={<Outerverse />}
          />

          <Route
            exact
            path="/terms"
            element={<Terms />}
          />

          <Route
            exact
            path="/policy"
            element={<Policy />}
          />

          <Route
            exact
            path="/vault/mbh"
            element={
              <MBHVault
                metamaskConnected={metamaskConnected}
                setMetamaskConnnected={setMetamaskConnnected}
                account={account}
                setDisclaimerModal={setDisclaimerModal}
              />
            }
          />

          <Route
            exact
            path="/vault/mla"
            element={
              <MLAVault
                metamaskConnected={metamaskConnected}
                setMetamaskConnnected={setMetamaskConnnected}
                account={account}
                setDisclaimerModal={setDisclaimerModal}
              />
            }
          />

          <Route
            exact
            path="/vault/huntress"
            element={
              <HuntressVault
                metamaskConnected={metamaskConnected}
                setMetamaskConnnected={setMetamaskConnnected}
                account={account}
                setDisclaimerModal={setDisclaimerModal}
              />
            }
          />

          <Route
            exact
            path="/vault/mbc"
            element={
              <MBCVault
                metamaskConnected={metamaskConnected}
                setMetamaskConnnected={setMetamaskConnnected}
                account={account}
                setDisclaimerModal={setDisclaimerModal}
              />
            }
          />

          <Route
            exact
            path="/crates"
            element={
              <CratePage
                metamaskConnected={metamaskConnected}
                setMetamaskConnnected={setMetamaskConnnected}
                account={account}
                setDisclaimerModal={setDisclaimerModal}
              />
            }
          />

          <Route path="*" element={<Navigate to="/" />} />

        </Routes>
      </Router>

      <Modal
        appElement={document.getElementById("root")}
        isOpen={disclaimerModal}
        onRequestClose={handleClose}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "overlay-after",
          beforeClose: "overlay-before",
        }}
        className={{
          base: "content-base",
          afterOpen: "content-after terms-modal",
          beforeClose: "content-before",
        }}
        closeTimeoutMS={500}
      >
        <div className="terms-notify-logo">
          <img src={mbh_icon} alt="mbh-logo" />
        </div>
        <div className="close">
          <i className="fa close-icon" onClick={handleClose}>
            &#xf00d;
          </i>
        </div>
        <div className="disclaimer-msg">
          <p>
            By clicking connect wallet, you agree to <a href="https://theouterverse.io/terms" target="_blank" rel="noreferrer">MBHC LTD terms of service</a>
          </p>
        </div>
      </Modal>
    </>
  );
}

export default App;
