import axios from 'axios';
import { useState } from 'react';
import swal from "sweetalert";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    mode: 'no-cors',
    headers: {
        'Content-Type': 'application/json'
    }
});

const usePostAPI = () => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async (url, requestBody) => {
        try {
            setLoading(true);
            const response = await api.post(url, requestBody);
            if(response.status === 200) {
                return response.data;
            } else {
                setError(response.data.message);
            }
        } catch (error) {   
            swal("Sorry!", "Network Error", "error");
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { error, loading, fetchData };
};

export default usePostAPI;
