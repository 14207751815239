import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Animated } from "react-animated-css";
import "./vault.css";
// import { setMBCMetadata, getMBCMetadataFailed } from '../../redux/reducers/vault';
import usePostAPI from "../../hooks/usePostAPI";
import opensea_icon from "../../assets/images/opensea_icon.png";

export const MBCVaultMain = (props) => {
    const { metamaskConnected, account, setMetamaskConnnected, setDisclaimerModal } = props;
    // const vaultData = useSelector((state) => state.vault.mbc);
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState("");
    const [metas, setMetas] = useState([]);

    const { fetchData } = usePostAPI();

    useEffect(() => {
        if (account) {
            fetchData('/mbc/getMetadata', { address: account }).then(res => {
                if (res?.success && res?.metas?.length > 0)
                    setMetas(res.metas);
                    // dispatch(setMBCMetadata({
                    //     metas: res.metas,
                    // }));
                else {
                    setErrorMsg("Haven't joined our empire yet? Get your Meta Bounty Crate today.");
                }
                    //dispatch(getMBCMetadataFailed());
            });
        }
    }, [account]);

    // useEffect(() => {
    //     if (vaultData.errorMsg && vaultData.errorMsg != '')
    //         setErrorMsg(vaultData.errorMsg);
    //     else
    //         setErrorMsg('');
    // }, [vaultData]);

    const handleConnectWallet = async () => {
        try {
            setDisclaimerModal(true);
            if (window.ethereum) {
              await window.ethereum.enable();
              setMetamaskConnnected(true);
              window.location.reload();
            } else {
              window.open("https://metamask.io/", "_blank");
            }
          } catch (e) {
            console.log(e);
          }
    };

    return (
        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={0}>
            <header id="mbcvault">
                <div className="intro bg-block">
                    <div className="gradient-overlay bg-block"></div>
                    <div className="header-content text-center">
                        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1100}>
                            <h1 className="gradient-text section-title">Your MBC Vault</h1>
                        </Animated>
                        <div className="clearfix"></div>
                        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1200}>
                            <div>
                                {metamaskConnected ? (
                                    <button className="btn btn-sm gradient-btn">
                                        {account &&
                                            <>
                                                {account.substring(0, 5)}...{account.substring(
                                                    account.length - 4
                                                )}
                                            </>
                                        }
                                    </button>
                                ) : (
                                    <>
                                        <p>Connect a wallet that is holding your Meta Bounty Crates to view your collection</p>
                                        <button className="btn btn-sm gradient-btn" onClick={handleConnectWallet}>CONNECT WALLET</button>
                                    </>
                                )}
                            </div>
                        </Animated>
                    </div>
                    {errorMsg ? (
                        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1000}>
                            <div className="vault-none-container">
                                <p className="text-center vault-error">{errorMsg}</p>
                                <a href="https://opensea.io/collection/mbhcrates" target="_blank" className="btn btn-lg gradient-btn" rel="noreferrer">
                                    <img src={opensea_icon} alt="opensea-icon" /> Buy on Opensea
                                </a>
                            </div>
                        </Animated>
                    ) : (
                        account && (
                            <>
                                <Animated animationIn="zoomIn" animationOut="fadeOut" animationInDelay={1300}>
                                    <div className="vault-container">
                                        <div className="row">
                                            {metas?.map((e, i) => {
                                                return (
                                                    <div className="col-sm-6 text-center" key={i}>
                                                        <img src={e.normalized_metadata?.image} alt="bounty"></img>
                                                        <h3>{e.name + "#" + e.token_id}</h3>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Animated>
                            </>
                        )
                    )}
                </div>
            </header>
        </Animated>
    );
};
