import { useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import roadmap from "../../assets/images/roadmap/roadmap.png";
import "./roadmap.css";
import blue_planet from "../../assets/images/roadmap/blue-planet.png";
import pink_planet from "../../assets/images/roadmap/pink-planet.png";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const roadmap_cards = [
  {
    no: "01",
    title: "BOUNTY BABY MINT"
  },
  {
    no: "02",
    title: "INTO THE<br />OUTERVERSE"
  },
  {
    no: "03",
    title: "MBH CRATES MINT"
  },
  {
    no: "04",
    title: "SANDBOX AND<br />NFT WORLDS"
  },
  {
    no: "05",
    title: "MBH CRATES REVEAL"
  },
  {
    no: "06",
    title: "BUYING AND SELLING<br />SHIP COMPONENTS"
  },
  {
    no: "07",
    title: "MERGE TO<br />CREATE SHIPS"
  },
  {
    no: "08",
    title: "P2E GAME WITH<br />SHIP STAKING+MODIFIERS<br />COMPETE TO EARN PRIZES.",
  }
];

export const Roadmap = () => {
  const container = useRef();

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container.current.querySelector("div"),
        scroller: ".scroller",
      },
    });

    tl.fromTo(
      document.querySelectorAll(".roadmap-blue-planet"),
      { opacity: 0, scale: 0 },
      { duration: 1, ease: "power4.inOut", opacity: 1, scale: 1 },
      0.5
    );
    tl.fromTo(
      document.querySelectorAll(".roadmap-title span"),
      { opacity: 0, y: -100 },
      { duration: 0.5, ease: "power4.inOut", opacity: 1, y: 0 },
      0
    );
    tl.fromTo(
      document.querySelectorAll(".roadmap-title h1"),
      { opacity: 0, scale: 0 },
      { duration: 1, ease: "power4.out", opacity: 1, scale: 1 },
      0.5
    );
    tl.fromTo(
      document.querySelectorAll(".roadmap-body"),
      { opacity: 0 },
      { duration: 1, ease: "power4.inOut", opacity: 1 },
      1.5
    );
    tl.fromTo(
      document.querySelectorAll(".roadmap-card"),
      { opacity: 0, x: 50 },
      { duration: 0.5, ease: "power4.in", stagger: 0.4, opacity: 1, x: 0},
      2.1
    );
    tl.fromTo(
      document.querySelectorAll(".roadmap-pink-planet"),
      { opacity: 0, scale: 0 },
      { duration: 1, ease: "power4.inOut", opacity: 1, scale: 1 },
      4
    );
  }, [])

  return (
    <div className="section roadmap container-fluid bg-block text-center" id="roadmap" ref={container}>
      <img src={blue_planet} className="roadmap-blue-planet"/>
      <img src={pink_planet} className="roadmap-pink-planet"/>
      <div className="roadmap-title">
        <span>PROJECT</span>
        <h1>ROADMAP</h1>
      </div>
      <div className="roadmap-body">
        <img className="d-none sm-hide roadmap-img" alt="roadmap" src={roadmap} />
        {
          roadmap_cards.map((e, i) => {
            return (
              <div className="roadmap-card d-flex align-items-center" key={i}>
                <h4 className="roadmap-card-number">{e.no}</h4>
                <img className="d-block sm-show" src={"../img/image/roadmap-item" + i + ".png"} />
                <h4 dangerouslySetInnerHTML={{ __html: e.title }}></h4>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};
