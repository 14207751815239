import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import gsap from "gsap";
import SplitType from "split-type";
import "./footer.css";

export const Footer = () => {
    useEffect(() => {
        const footerP = new SplitType(".footer p", { types: "chars" });
        gsap.timeline({
            scrollTrigger: {
                trigger: ".footer",
                scroller: ".scroller",
            },
        })
            .fromTo(
                document.querySelectorAll(".footer h1"),
                { opacity: 0, scale: 0 },
                { duration: 1.5, ease: "power4.inOut", opacity: 1, scale: 1 },
                0)
            .fromTo(
                document.querySelectorAll(".footer ul li"),
                { opacity: 0, y: -50 },
                { duration: 1, ease: "power4.inOut", opacity: 1, y: 0, stagger: 0.4 },
                1)
            .fromTo(
                footerP.chars,
                { opacity: 0, },
                { opacity: 1, stagger: { amount: 1 }, ease: "power4.out" },
                2
            );
    }, []);

    return (
        <div className="footer">
            <h1>OUTERVERSE</h1>
            <ul>
                <li><HashLink smooth to="/terms#">TERMS OF USE</HashLink></li>
                <li><HashLink smooth to="/policy#">PRIVACY POLICY</HashLink></li>
            </ul>
            <p>All Rights Reserved</p>
        </div>
    )
}