import { createSlice } from '@reduxjs/toolkit';

const initialCrateMintState = {
    totalToken: 0,
    mintable: {},
    mintData: {}
};

export const cratesMintSlice = createSlice({
    name: 'cratemint',
    initialState: initialCrateMintState,
    reducers: {
        setMintable: (state, action) => {
            state.mintable = { ...action.payload };
            state.mintData = {};
        },
        checkMintableFailed: (state, action) => {
            state.mintable = { ...action.payload };
            state.mintData = {};
        },
        setMintData: (state, action) => {
            state.mintData = { ...action.payload };
        },
        getMintDataFailed: (state, action) => {
            state.mintData = {};
        },
        changeMintedCounts: (state, action) => {
            if(state.mintable?.count > 0) {
                state.mintable.count = parseInt(state.mintable.count) - parseInt(action.payload);
                state.mintable.minted = parseInt(state.mintable.minted) + parseInt(action.payload);
            }
        }
    }
})

export const { setMintable, checkMintableFailed, setMintData, getMintDataFailed, changeMintedCounts } = cratesMintSlice.actions;

export default cratesMintSlice.reducer;