import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactLoading from "react-loading";
import mbh_icon from "../../assets/images/mbh_icon.png";
import { setHuntressMetadata, setHuntressUserInfo, getHuntressMetadataFailed, getHuntressUserInfoFailed } from '../../redux/reducers/vault';
import { setHuntressRewards, checkHuntressRewardsFailed } from "../../redux/reducers/rewards";
import usePostAPI from "../../hooks/usePostAPI";
import "./vault.css";

export const HuntressVaultMain = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const vaultData = useSelector((state) => state.vault.huntress);
  const rewards = useSelector((state) => state.rewards.huntress);
  const dispatch = useDispatch();
  const [metas, setMetas] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [directPayout, setDirectPayout] = useState(0);
  const [affiliatePayout, setAffiliatePayout] = useState(0);
  const [directPaidAmount, setDirectPaidAmount] = useState(0);
  const [affiliatePaidAmount, setAffiliatePaidAmount] = useState(0);
  const [showDetails, setShowDetails] = useState([]);

  const { fetchData } = usePostAPI();

  useEffect(() => {
    if (account) {
        fetchData('/huntress/getMetadata', { address: account }).then(res => {
            if (res?.success)
                dispatch(setHuntressMetadata({
                    metadata: res.metas,
                }));
            else
                dispatch(getHuntressMetadataFailed());
        });
        fetchData('/huntress/getUserInfo', { address: account }).then(res => {
            if (res?.success)
                dispatch(setHuntressUserInfo({
                    userInfo: res.userInfo
                }));
            else
                dispatch(getHuntressUserInfoFailed({ ...res }));
        });
        fetchData('/huntress/checkRewards', { address: account }).then(res => {
            if (res?.success)
                dispatch(setHuntressRewards({
                    ...res
                }));
            else
                dispatch(checkHuntressRewardsFailed({ ...res }));
        });
      setShowDetails([]);
    }
  }, [account]);

  useEffect(() => {
    if(vaultData.errorMsg && vaultData.errorMsg != '')
      setErrorMsg(vaultData.errorMsg);
    else {
      setMetas(vaultData.metadata);
    }
    if(vaultData.userInfoMsg || vaultData.userInfo) {
      setDashboardLoading(false);
      setUserInfo(vaultData.userInfo);
    }
  }, [vaultData]);

  useEffect(() => {
    setDirectPayout(rewards.directAmount + rewards.directPaidAmount);
    setAffiliatePayout(rewards.affiliateAmount + rewards.affiliatePaidAmount);
    setDirectPaidAmount(rewards.directPaidAmount);
    setAffiliatePaidAmount(rewards.affiliatePaidAmount);
  }, [rewards]);

    return (
        <div className="vault-main" id="main">
            <div className="container text-center">
            { errorMsg? (
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: "spring", stiffness: 30, delay: 0.5 }}
                viewport={{ once: true, amount: 0 }}
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <h2 className="text-center" dangerouslySetInnerHTML={{__html: errorMsg}}></h2>
                  <div className="d-flex gap-5 mt-5 vault-btn-block">
                    <div className="button-box">
                      <a href="https://marketplace.theouterverse.io" className="button-shape common-btn green-gradient">
                          <div className="second-btn-bg"></div>
                          <img src={mbh_icon} alt="mbh_icon" />
                          <span>BUY ON MBH MARKETPLACE</span>
                      </a>
                    </div>
                  </div>
                </div>
              </motion.div>
              ) : (
              account&&(
                <>
                <motion.div
                    initial={{ opacity: 0, y: 200 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ type: "spring", stiffness: 30, delay: 0.5 }}
                    viewport={{ once: true, amount: 0 }}
                >
                <div className="vault-container">
                  <div className="row">
                  {metas?.map((e,i) => {
                    return(
                      <div className="col-md-4 col-sm-6 text-center mt-5" key={i}>
                        <img src={e.normalized_metadata?.image} alt="bounty"></img>
                        <h2 className="mt-2" style={{fontSize: "xx-large"}}>{e.name + "#" + e.token_id}</h2>
                      </div>
                    )
                  })}
                  </div>
                </div>
                </motion.div>
                </>
              )
            )}
            </div>
            { account && ( dashboardLoading ? (
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: "spring", stiffness: 30, delay: 0.5 }}
                viewport={{ once: true, amount: 0 }}
              >
                <div className="d-flex align-items-center justify-content-center mt-5">
                <ReactLoading
                  type="spin"
                  color="#f8a621"
                  height={120}
                  width={120}
                />
              </div>
              </motion.div>
              ):(
                userInfo && userInfo!=null && (
                <motion.div
                  initial={{ opacity: 0, y: 200 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ type: "spring", stiffness: 30, delay: 0.5 }}
                  viewport={{ once: true, amount: 0 }}
                >
                  <div className="container dashboard text-center">
                    <h1 className="dashboard-title mb-5">HUNTRESS TRIBE</h1>
                    <Tabs>
                      <TabList className="vault-menu d-flex align-items-center justify-content-center gap-2">
                        <Tab className="button-shape common-btn blue-gradient border-0">MY PROFILE</Tab>
                        <Tab className="button-shape common-btn blue-gradient border-0">MY SQUADRON</Tab>
                        <Tab className="button-shape common-btn blue-gradient border-0">FREE REWARDS</Tab>
                        <Tab className="button-shape common-btn blue-gradient border-0">MY NFTS</Tab>
                      </TabList>
    
                      <TabPanel>
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="dashboard-content text-start">
                            <p>Full Name: {userInfo.mdetails.username}</p>
                            <p>Call Sign: {userInfo.mdetails.username}</p>
                            <p>Referred Call Sign: {userInfo.mdetails.parentusername}</p>
                            <p>MetaMaskID: {userInfo.mdetails.metamaskid}</p>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="d-flex align-items-center justify-content-center">
                          {
                            userInfo.squadron.length > 0 ? (
                              <div className="dashboard-content">
                                {
                                  userInfo.squadron.map((item,index) => {
                                    return(
                                      <React.Fragment key={index}>
                                        <p onClick={() => setShowDetails(prev => {
                                          const newState = [...prev];
                                          newState[index] = !newState[index];
                                          return newState;
                                        })} className="squadron-level"><strong>Level {item.level} Enlistment: {item.squadron.length} with {item.squadron.reduce((a, b) => a + (b['nfts'] || 0), 0)} NFTs</strong></p>
                                        <div className={showDetails[index] ? 'squadron-detail-box' : 'hidden'}>
                                          {
                                            item.squadron.length > 0 && item.squadron.map((child, idx) => {
                                              return(
                                                <p key={idx}>{child.username}: {child.nfts} NFTs</p>
                                              )
                                            })
                                          }
                                        </div>
                                      </React.Fragment>
                                    );
                                  })
                                }
                              </div>
                            ) : (
                              <div className="dashboard-content text-center">
                                <p>NO SQUADRON</p>
                              </div>
                            )
                          }
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="text-center mt-5">
                          <Tabs>
                            <TabList className="dashboard-btn-area d-flex align-items-center justify-content-center gap-2">
                              <Tab className="button-shape common-btn green-gradient">TOTAL</Tab>
                              <Tab className="button-shape common-btn green-gradient">CLAIMED</Tab>
                              <Tab className="button-shape common-btn green-gradient">CLAIMABLE</Tab>
                            </TabList>
                            <TabPanel>
                              <div className="dashboard-content" style={{display:"inline-block"}}>
                                <p>DIRECT FREE REWARDS : {directPayout.toFixed(3)} ETH</p>
                                <p>AFFILIATE FREE REWARDS : {affiliatePayout.toFixed(3)} ETH</p>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className="dashboard-content" style={{display:"inline-block"}}>
                                  <p>DIRECT FREE REWARDS : {directPaidAmount.toFixed(3)} ETH</p>
                                  <p>AFFILIATE FREE REWARDS : {affiliatePaidAmount.toFixed(3)} ETH</p>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className="dashboard-content" style={{display:"inline-block"}}>
                                <p>DIRECT FREE REWARDS : {(directPayout-directPaidAmount).toFixed(3)} ETH</p>
                                <p>AFFILIATE FREE REWARDS : {(affiliatePayout-affiliatePaidAmount).toFixed(3)} ETH</p>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="dashboard-content text-start">
                              <p>Total NFTs: {userInfo.mdetails.nfts}</p>
                              <p>MetaMaskID: {userInfo.mdetails.metamaskid}</p>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </motion.div>
                )
              ))
            }
        </div>
    );
}
