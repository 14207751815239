import { useState, useEffect, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const Terms = () => {
    const container = useRef();
    const terms = useRef();

    const [active, setActive] = useState(false);
    useEffect(() => {
        console.log(container.current);
        const onWheel = () => terms?.current?.getBoundingClientRect().top < -100 ? setActive(true) : setActive(false);
        window.removeEventListener('wheel', onWheel);
        window.addEventListener('wheel', onWheel, { passive: true });
        return () => window.removeEventListener('scroll', onWheel);
    }, []);

    useEffect(() => {
        gsap.set(container.current, { overflowY: 'scroll' });

        gsap.timeline({
            scrollTrigger: {
                trigger: ".terms",
                scroller: ".scroller",
            },
        })
            .fromTo(
                document.querySelectorAll(".terms h1"),
                { opacity: 0, scale: 0 },
                { duration: 1, ease: "power4.out", opacity: 1, scale: 1 },
                0)
            .fromTo(
                document.querySelectorAll(".terms p, .terms h3, .terms li"),
                { opacity: 0, x: -50 },
                { duration: 0.5, ease: "power4.out", opacity: 1, x: 0, stagger: { amount: 5 } },
                0.5)
    }, []);

    return (
        <>
            <Header doAnim={false} isActive={active} />
            <div className="scroller" ref={container}>
                <div className="terms" ref={terms}>
                    <div className="container pb-5">
                        <h1 className="text-center">TERMS OF USE</h1>
                        <p>These Terms of Use (“Terms”) apply to your purchase, sale and display of Meta Bounty Hunter non-fungible tokens (“MBHC LTD NFTs”). Some purchases of MBHC LTD NFTs may include special experience opportunities and community perks. Please view terms regarding such [experiences here] (“Experience Terms”). To the extent there is a conflict between these Terms and the Experience Terms, these Terms control.</p>
                        <p>These Terms are entered into between you and Meta Bounty Hunters  (“Company,” “we,” “MBHC LTD” or “us”). These Terms expressly incorporate any other documents referenced herein (such as our Privacy Policy) and govern your access to and use of this site www.theouterverse.io (the “Site”), as well as all content, functionality, and services offered on or through the Site, including the MBHC LTD NFTs.</p>

                        <h3 className="mt-3">1. Reviewing and Accepting These Terms</h3>
                        <p>Please read these Terms carefully, as they set out your rights and responsibilities when you use this Site to buy MBHC LTD NFTs (the “Services”). When each MBHC LTD NFT is sold for the first time, the agreement for sale is between the Company and the initial purchaser. If the initial purchaser decides to sell the MBHC LTD NFT through this Site, then this Site serves only as a platform that facilitates transactions between a buyer and a seller and the Company is not a party to any agreement between such buyer and seller of MBHC LTD NFTs or between any other users.</p>
                        <p>All MBHC LTD NFTs are stored on and accessible through the Ethereum blockchain. As such, the Company does not maintain the MBHC LTD NFTs on this Site and, aside from transferring control of the MBHC LTD NFT to the initial purchaser of the MBHC LTD NFT, the Company has no control over the transfer, storage, ownership or maintenance of the MBHC LTD NFT.</p>
                        <p>When you connect your cryptocurrency wallet to the Site using a trusted service provider such as MetaMask, you accept and agree to be bound and abide by these Terms and all of the terms incorporated herein by reference. By agreeing to these Terms, you hereby certify that you are at least 18 years of age. If you do not agree to these Terms, you must not access or use the Site.</p>
                        <p>Please note that Section 17 contains an arbitration clause and class action waiver. By agreeing to these Terms, you agree to resolve all disputes through binding individual arbitration, which means that you waive any right to have the dispute decided by a judge or jury, and you waive any right to participate in collective action, whether that be a class action, class arbitration, or representative action. You have the ability to opt-out of this arbitration clause by sending us notice of your intent to do so within thirty (30) days of your initial agreement to these Terms.</p>
                        <p>We reserve the right to change or modify these Terms at any time and in our sole discretion. You agree and understand that by accessing or using the Site following any change to these Terms, you are agreeing to the revised Terms and all of the terms incorporated therein by reference.</p>
                        <p>Review the Terms each time you access the Site to ensure that you understand how the Terms apply to your activities on the Site.</p>

                        <h3 className="mt-3">2. Linking Your Cryptocurrency Wallet</h3>
                        <p>When you link your cryptocurrency wallet, you understand and agree that you are solely responsible for maintaining the security of your wallet and your control over any wallet-related authentication credentials, private or public cryptocurrency keys, non-fungible tokens or cryptocurrencies that are stored in or are accessible through your wallet. Any unauthorized access to your cryptocurrency wallet by third parties could result in the loss or theft of MBHC LTD NFTs and/or funds held in your wallet and any associated wallets, including any linked financial information such as bank account(s) or credit card(s). We are not responsible for managing and maintaining the security of your cryptocurrency wallet nor for any unauthorized access to or use of your cryptocurrency wallet. If you notice any unauthorized or suspicious activity in your cryptocurrency wallet that seems to be related to this Site, please notify us immediately.</p>

                        <h3 className="mt-3">3. Ownership</h3>
                        <p>Unless otherwise indicated in writing by us, the Site, all content, and all other materials contained therein, including, without limitation, our logos, and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof (collectively, “Site Content”) are the proprietary property of MBHC LTD or our affiliates, licensors, or users, as applicable. The MBHC LTD logo and any MBHC LTD product or service names, logos, or slogans that may appear on the Site or elsewhere are trademarks of MBHC LTD or our affiliates, and may not be copied, imitated or used, in whole or in part, without our prior written permission.</p>
                        <p>You may not use any Site Content or link to the Site without our prior written permission. You may not use framing techniques to enclose any Site Content without our express written consent. In addition, the look and feel of the Site Content, including without limitation, all page headers, custom graphics, button icons, and scripts constitute the service mark, trademark, or trade dress of MBHC LTD and may not be copied, imitated, or used, in whole or in part, without our prior written permission.</p>

                        <h3 className="mt-3">4. Terms of Sale</h3>
                        <p>By placing an order on the Site, you agree that you are submitting a binding offer to purchase a MBHC LTD NFT or other Service. If you are the initial purchaser of a MBHC LTD NFT or you are purchasing a Service, then all amounts due are to be paid to MBHC LTD. If you are not the initial purchaser of a MBHC LTD NFT, then amounts may be paid to the-then holder of the MBHC LTD NFT. You also acknowledge and agree that Company receives 10% of every subsequent sale of a MBHC LTD NFT (“Royalty”). For example, if you are the initial purchaser, and you sell a MBHC LTD NFT for $2000 to a subsequent purchaser, $200 will automatically be transferred to the Company and you will receive the difference depending on the platform used for the sale. Company has the right to collect Royalties for MBHC LTD NFT sales in perpetuity and may use those funds in any manner Company sees fit.</p>
                        <p>Each MBHC LTD NFT comes with access to Utilities and Free Rewards for the holder. These Utilities and Free Rewards are active for a period of 4 years from the public mint date. Any free rewards must be claimed within six months of becoming eligible or they will expire and be forfeited.  ALL Free Rewards and NFT utilities are nonrefundable and nonexchangeable. Free Rewards and Utility will continue to be active for no longer than four years from the initial mint date, but may be terminated earlier by MBHC LTD at its discretion, including but not limited to the Free Rewards Utility. Any unclaimed free rewards will expire and become unredeemable four years after the public mint date. Free Rewards will be immediately forfeited without regards to any remaining time on any six month periods when that four year period has completed. All Rewards are free and distributed at the discretion of MBHC LTD and no liability can be attributed to MBHC LTD or its partners for failure to offer any specific Reward or Utility to an NFT Holder.</p>

                        <h3 className="mt-3">5. As such, if you sell a MBHC LTD NFT on a third-party NFT marketplace, you agree to include a statement substantially similar to the following in the description of the NFT: “10% Royalty Applies. See MBHC LTD Terms for details.”</h3>
                        <p>In addition, when you buy or sell a MBHC LTD NFT on this Site, you agree to pay all applicable fees associated with the transaction and you authorize the Company to automatically charge and collect such fees from your payment. We will always display a breakdown of any transaction or other fees prior to your purchase or sale of a MBHC LTD NFT.</p>
                        <p>No refunds are permitted</p>

                        <h3 className="mt-3">6. Intellectual Property</h3>
                        <p>Other than Site Content, all other trademarks, product names, and logos on the Site are the property of their respective owners and may not be copied, imitated, or used, in whole or in part, without the permission of the applicable trademark holder. Without limiting the foregoing, if you believe that third-party material hosted on the Site infringes your copyright or trademark rights, please file a notice of infringement by contacting the Designated Copyright Agent listed below.</p>
                        <p>Your notice must contain the following information as required by the Digital Millennium Copyright Act (17 U.S.C. §512) (“DMCA”):</p>
                        <ul>
                            <li>The full name and a physical or electronic signature of the person authorized to act on behalf of the copyright owner;</li>
                            <li>Identification of the copyrighted work claimed to have been infringed. If multiple copyrighted works are covered by your notice, you may provide a representative list of the copyrighted works that you claim have been infringed;</li>
                            <li>Reasonably sufficient detail to enable us to identify and locate the copyrighted work that is claimed to be infringing (e.g. a link to the page on the Site that contains the material);</li>
                            <li>A mailing address, telephone number, and email address where we can contact you;</li>
                            <li>A statement that you have a good faith belief that the disputed use of the copyrighted work is not authorized by the copyright owner, its agent, or the law; and</li>
                            <li>A statement made by you, under penalty of perjury, that the information in the notice is accurate and that you are the copyright owner or are authorized to act on behalf of the copyright owner.</li>
                        </ul>
                        <p>Please submit your notice by email to <a href="mailto:metabountyhunters@gmail.com">metabountyhunters@gmail.com</a></p>
                        <p>Once you provide us with an adequate notice as described above, we will respond expeditiously and take whatever action, in our sole discretion, that is deemed appropriate including removal of the disputed copyrighted work from the Site.</p>
                        <p>Counter-Notice:</p>
                        <p>If you believe that a DMCA notice of copyright infringement has been improperly submitted against you, you may submit a counter-notice to the Designated Agent with the following information required by the DMCA:</p>
                        <ul>
                            <li>Your physical or electronic signature;</li>
                            <li>Identification of the copyrighted work that has been removed or to which access has been disabled including a link to the page on the Site that contained the material before it was removed or disabled;</li>
                            <li>A statement under penalty of perjury that you have a good faith belief that the copyrighted work was removed or disabled as a result of mistake or misidentification;</li>
                            <li>Your name, address, e-mail address, and telephone number; and</li>
                            <li>A statement that you (i) consent to the jurisdiction of the Federal District Court in the judicial district where your address is located if the address is in the United States, or the United District Court for Wyoming if your address is located outside of the United States, and (ii) accept service of process from the person who provided the DMCA notice of the alleged copyright infringement.</li>
                        </ul>
                        <p>Please submit your notice to <a href="mailto:metabountyhunters@gmail.com">metabountyhunters@gmail.com</a></p>
                        <p>In the event that the Company receives a counter-notice in compliance with the above requirements, we will provide the person who submitted the DMCA copyright infringement notice with a copy of the counter-notice, informing them that the Company will replace the removed material in 10 business days from the date of the counter-notice unless the Company first receives notice from the person who submitted the DMCA copyright infringement notice that they have filed an action seeking a court order to restrain the allegedly infringing activity.</p>
                        <p>PLEASE NOTE THAT MBHC LTD INTENDS TO COMPLY WITH ALL PROVISIONS OF THE DIGITAL MILLENNIUM COPYRIGHT ACT, BUT WILL NOT UNILATERALLY TAKE RESPONSIBILITY FOR POLICING AND REMOVING MATERIAL THOUGHT TO BE INFRINGING.</p>
                        <p>We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Site Content. In return, you agree not to engage, or assist, in any activity that violates any law, statute, ordinance, regulation, or sanctions program, including but not limited to the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”), or that involves proceeds of any unlawful activity; not to engage in any other activity or behavior that poses a threat to MBHC LTD, (e.g., by distributing a virus or other harmful code, or through unauthorized access to the Site or other users’ cryptocurrency wallets and not to interfere with other users’ access to or use of the Services.</p>
                        <p>You also agree not to (1) distribute, publish, broadcast, reproduce, copy, retransmit, or publicly display any Site Content; (2) modify or create derivative works from the Site Content, or any portion thereof; (3) use any data mining, robots, or similar data gathering or extraction methods on the Site Content; (4) download any portion of the Site Content, other than for purposes of page caching, except as expressly permitted by us.</p>
                        <p>With respect to the MBHC LTD NFTs, each purchaser of a MBHC LTD NFT is granted an exclusive, limited license to such MBHC LTD NFT and its content to access, use, or store such MBHC LTD NFT and its content solely for their personal, non-commercial purposes. MBHC LTD NFTs are a limited-edition digital creation based upon content that may be trademarked and/or copyrighted by Company. Unless otherwise specified, your purchase of a MBHC LTD NFT does not give you the right to publicly display, perform, distribute, sell or otherwise reproduce the MBHC LTD NFT or its content for any commercial purpose. You further agree that you are not receiving any copyright interest in the MBHC LTD NFT or its content, and indeed, you agree that Company may sell, license, modify, display, broadcast and create derivative works based upon your MBHC LTD NFT or its content. Any commercial exploitation of the MBHC LTD NFT could subject you to claims of copyright infringement. If you sell a MBHC LTD NFT through the Site, you agree that you will not have any claims against us for any breach of these Terms by a purchaser. If you purchase a MBHC LTD NFT on the Site, you hereby agree to hold us and the seller of such MBHC LTD NFT harmless from and against any and all violations or breaches of these Terms.</p>
                        <p>If you are unsure whether a contemplated use of the Site Content or a MBHC LTD NFT and its content would violate these Terms, please contact us at <a href="mailto:metabountyhunters@gmail.com">metabountyhunters@gmail.com</a>.</p>

                        <h3 className="mt-3">7. Taxes</h3>
                        <p>We are not responsible for determining the withholding, sales, use, value added, transfer or other taxes, together with any interest and penalties imposed with respect thereto (“Taxes”), that may apply to transactions on this Site. You agree that you are solely responsible for determining what, if any, Taxes apply to your transactions and to withhold, collect, report and remit the correct amounts of Taxes to the appropriate taxing authorities. Unless otherwise indicated on an applicable invoice, amounts due on this Site are exclusive of sale, use, value added or similar Taxes (“Sales Taxes”). This means that Sales Taxes become your sole responsibility. Upon our request, you agree to promptly provide a properly executed Internal Revenue Service Form W-9 or applicable Internal Revenue Service W-8 and any other tax form that is reasonably required by us to comply with our tax reporting obligations.</p>

                        <h3 className="mt-3">8. Privacy</h3>
                        <p>You acknowledge and agree to the collection, use, and disclosure of your personal information in accordance with our <HashLink smooth to="/policy#">Privacy Policy</HashLink>, which is incorporated into these Terms.</p>

                        <h3 className="mt-3">9. Modifications</h3>
                        <p>You agree and understand that we may modify part or all of this Site or the Services without notice, and that we may update these Terms and any other document incorporated by reference therein at any time.</p>

                        <h3 className="mt-3">10. Risks</h3>
                        <p>Please note the following risks in accessing, purchasing, selling or using MBHC LTD NFTs: The price and liquidity of blockchain assets, including MBHC LTD NFTs, are extremely volatile and may be subject to large fluctuations. Fluctuations in the price of other digital assets could materially and adversely affect MBHC LTD NFTs, which may also be subject to significant price volatility. Legislative and regulatory changes or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of MBHC LTD NFTs. MBHC LTD NFTs are not legal tender and are not backed by any government. Transactions in MBHC LTD NFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable. Some transactions in MBHC LTD NFTs shall be deemed to be made when recorded on a public ledger, which is not necessarily the date or time that you initiated the transaction. The value of MBHC LTD NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for MBHC LTD NFTs, which may result in the potential for permanent and total loss of value of a particular MBHC LTD NFT should the market for that MBHC LTD NFT disappear.</p>
                        <p>You agree and understand that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself, and that we do not give advice or recommendations regarding MBHC LTD NFTs, including the suitability and appropriateness of, and investment strategies for, MBHC LTD NFTs. You agree and understand that you access and use this Site at your own risk; however, this brief statement does not disclose all of the risks associated with MBHC LTD NFTs and other digital assets. You agree and understand that we will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using MBHC LTD NFTs, however caused.</p>

                        <h3 className="mt-3">11. Disclaimers</h3>
                        <p>EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY MBHC LTD , THE SITE AND ANY SITE CONTENT CONTAINED THEREIN, AND ANY AND ALL MBHC LTD NFTS LISTED THEREIN ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. MBHC LTD (AND ITS SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL (1) MEET YOUR REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.</p>
                        <p>WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR NOT TAKEN IN RELIANCE ON MATERIAL OR INFORMATION CONTAINED ON THE SITE. MBHC LTD DOES NOT REPRESENT OR WARRANT THAT SITE CONTENT IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.</p>
                        <p>WHILE WE ATTEMPT TO MAKE YOUR ACCESS TO AND USE OF THE SITE AND SITE CONTENT SAFE, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT THE SITE, SITE CONTENT, ANY MBHC LTD NFTS LISTED ON OUR SITE OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.</p>
                        <p>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF MBHC LTD NFTS INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS TO APPLICATIONS; (E) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTE FORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE OR MBHC LTD NFTS.</p>
                        <p>MBHC LTD NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT GUARANTEE THAT MBHC LTD OR ANY MBHC LTD PARTY CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY MBHC LTD NFT.</p>
                        <p>We are not responsible for sustained casualties due to vulnerability or any kind of failure, abnormal behavior of software (e.g., wallet, smart contract), blockchains or any other features of the MBHC LTD NFTs. MBHC LTD  is not responsible for casualties due to late report by developers or representatives (or no report at all) of any issues with the blockchain supporting MBHC LTD NFTs including forks, technical node issues or any other issues having fund losses as a result.</p>
                        <p>Nothing in these Terms shall exclude or limit liability of either party for fraud, death or bodily injury caused by negligence, violation of laws, or any other activity that cannot be limited or excluded by legitimate means.</p>
                        <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p>

                        <h3 className="mt-3">12. Limitation of Liability</h3>
                        <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL MBHC LTD  BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE SITE, SITE CONTENT, THE SERVICES OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF MBHC LTD  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE, SITE CONTENT, THE SERVICES OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM.</p>
                        <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF MBHC LTD ARISING OUT OF OR IN ANY WAY RELATED TO THIS AGREEMENT, THE ACCESS TO AND USE OF THE SITE, SITE CONTENT, MBHC LTD NFTS, OR ANY SERVICES PURCHASED ON THE SITE EXCEED THE GREATER OF (A) $100 OR (B) THE AMOUNT YOU HAVE PAID TO MBHC LTD  FOR THE SERVICES IN THE LAST TWELVE MONTHS OUT OF WHICH LIABILITY AROSE.</p>

                        <h3 className="mt-3">13. Indemnification</h3>
                        <p>To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless MBHC LTD , and our respective past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (individually and collectively, the “MBHC LTD Parties”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses) and costs (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to (a) your use or misuse of the Site, Site Content, or MBHC LTD NFTs, (c) your violation of these Terms, (c) your violation of the rights of a third party, including another user and (e) your failure to pay any Taxes or Sales Taxes in connection with your transactions on this Site or to provide us with a properly executed tax form described in Section 8. You agree to promptly notify us of any third party Claims and cooperate with the MBHC LTD Parties in defending such Claims. You further agree that the MBHC LTD Parties shall have control of the defense or settlement of any third party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND US.</p>

                        <h3 className="mt-3">14. Governing Law</h3>
                        <p>These Terms of Use, your rights and obligations, and all actions contemplated by, arising out of or related to these Terms shall be governed by the laws of the State of Wyoming, as if these Terms are a contract wholly entered into and wholly performed within the State of Wyoming. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THIS SITE AS CONTEMPLATED BY THESE TERMS SHALL BE DEEMED TO HAVE OCCURRED IN THE STATE OF WYOMING AND BE SUBJECT TO THE INTERNAL LAWS OF THE STATE OF WYOMING WITHOUT REGARD TO ITS CONFLICTS OF LAWS PROVISIONS.</p>

                        <h3 className="mt-3">15. Disputes and Arbitration Agreement</h3>
                        <p>Carefully read the following arbitration agreement ("Arbitration Agreement"). It requires you to arbitrate disputes with MBHC LTD  and limits the manner in which you can seek relief from us.</p>
                        <p>Applicability of Arbitration Agreement. You agree that any dispute or claim relating in any way to your access or use of the Site, to any Services sold or distributed through the Site, including MBHC LTD NFTs, or to any aspect of your relationship with MBHC LTD  will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify; and (2) you or MBHC LTD may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents).</p>
                        <p>Arbitration Rules and Forum. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to our registered agent United Corporate Services, 874 Walker Road, Suite C, in the City of Dover, County of Kent, 19904. The arbitration will be conducted by JAMS, an established alternative dispute resolution provider. Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at <a href="https://www.jamsadr.com/rules-streamlined-arbitration/" target="_blank">https://www.jamsadr.com/rules-streamlined-arbitration/</a>; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at <a href="https://www.jamsadr.com/rules-comprehensive-arbitration/" target="_blank">https://www.jamsadr.com/rules-comprehensive-arbitration/</a>. JAMS’s rules are also available at <a href="https://www.jamsadr.com/" target="_blank">jamsadr.com</a> or by calling JAMS at 800-352-5267. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver from JAMS, MBHC LTD will pay them for you. You may choose to have the arbitration conducted by telephone or video conference or based on written submissions, or you may request to meet in-person for arbitration in Wyoming. You agree that any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</p>

                        <p>Authority of Arbitrator. The arbitrator shall have exclusive authority to (a) determine the scope and enforceability of this Arbitration Agreement and (b) resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and MBHC LTD. The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and the Agreement (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and us. And you agree that to the extent monetary or non-monetary remedy or relief is granted, such request for relief may be enforced as needed by any court of competent jurisdiction.</p>
                        <p>Waiver of Jury Trial. YOU AND MBHC LTD HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and MBHC LTD are instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement, except as otherwise indicated in this Section 14. An arbitrator can award on an individual basis the same damages and relief as a court and must follow this Arbitration Agreement as a court would. However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.</p>
                        <p>Waiver of Class or Other Non-Individualized Relief. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A COLLECTIVE OR CLASS BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is issued stating that applicable law precludes enforcement of any part of this subsection’s limitations as to a given claim for relief, then that claim must be severed from the arbitration and brought in the state or federal courts located in the State of Wyoming. All other claims shall be arbitrated.</p>
                        <p>30-Day Right to Opt Out. You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt out to the following email address metabountyhunter@gmail.com. Your notice must include your name and address, the cryptocurrency wallet address you used to transact on this Site (if you have one), and an unequivocal statement that you want to opt out of this Arbitration Agreement. If you opt out of this Arbitration Agreement, all other parts of this Agreement will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.</p>

                        <h3 className="mt-3">16. Severability</h3>
                        <p>Except as provided herein, if any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect.</p>

                        <h3 className="mt-3">17. Survival of Agreement</h3>
                        <p>This Arbitration Agreement will survive the termination of your relationship with MBHC LTD.</p>

                        <h3 className="mt-3">18. Modification</h3>
                        <p>Notwithstanding any provision in these Terms to the contrary, we agree that if MBHC LTD makes any future material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such change becoming effective by writing to us at <a href="mailto:metabountyhunters@gmail.com">metabountyhunters@gmail.com</a></p>
                        <h3 className="mt-3">19. Severability</h3>
                        <p>If any term, clause, or provision of these Terms is held invalid or unenforceable, then that term, clause, or provision shall be severable from these Terms and will not affect the validity or enforceability of any remaining part of that term, cause, or provision, or any other terms, clause, or provision of these Terms.</p>
                        <h3 className="mt-3">20. Entire Agreement</h3>
                        <p>These Terms comprise the entire agreement between you and us relating to your access to and use of the Site, Site Content and any MBHC LTD NFTs you have purchased, and supersede any and all prior discussions agreements, and understandings of any kind. Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third party beneficiary rights upon any other person or entity.</p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Terms;