import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Animated } from "react-animated-css";
import header_hunters from "../../assets/images/mbh_header_bottom_rewards.png";
import rarity_icon from "../../assets/images/mbh_rarity_favicon.png";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./vault.css";
import { setMBHMetadata, setMBHUserInfo, getMBHMetadataFailed, getMBHUserInfoFailed } from '../../redux/reducers/vault';
import { setMBHRewards, checkMBHRewardsFailed } from '../../redux/reducers/rewards';
import usePostAPI from "../../hooks/usePostAPI";
import ReactLoading from "react-loading";
import mbh_icon from "../../assets/images/mbh_icon.png";

export const MBHVaultMain = (props) => {
    const { metamaskConnected, account, setMetamaskConnnected, setDisclaimerModal } = props;
    const vaultData = useSelector((state) => state.vault.mbh);
    const rewards = useSelector((state) => state.rewards.mbh);
    const dispatch = useDispatch();
    const [metas, setMetas] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [dashboardLoading, setDashboardLoading] = useState(true);
    const [directPayout, setDirectPayout] = useState(0);
    const [watchPayout, setWatchPayout] = useState(0);
    const [affiliatePayout, setAffiliatePayout] = useState(0);
    const [directPaidAmount, setDirectPaidAmount] = useState(0);
    const [watchPaidAmount, setWatchPaidAmount] = useState(0);
    const [affiliatePaidAmount, setAffiliatePaidAmount] = useState(0);
    const [showDetails, setShowDetails] = useState([]);

    const { fetchData } = usePostAPI();

    useEffect(() => {
        if (account) {
            fetchData('/mbh/getMetadata', { address: account }).then(res => {
                if (res?.success)
                    dispatch(setMBHMetadata({
                        mbh_metas: res.mbh_metas,
                        iconic_metas: res.iconic_metas,
                        baby_metas: res.baby_metas
                    }));
                else
                    dispatch(getMBHMetadataFailed());
            });
            fetchData('/mbh/getUserInfo', { address: account }).then(res => {
                if (res?.success)
                    dispatch(setMBHUserInfo({
                        userInfo: res.userInfo
                    }));
                else
                    dispatch(getMBHUserInfoFailed({ ...res }));
            });
            fetchData('/mbh/checkRewards', { address: account }).then(res => {
                if (res?.success)
                    dispatch(setMBHRewards({
                        ...res
                    }));
                else
                    dispatch(checkMBHRewardsFailed({
                        ...res
                    }));
            });
            setShowDetails([]);
        }
    }, [account]);

    useEffect(() => {
        if (vaultData.errorMsg && vaultData.errorMsg != '')
            setErrorMsg(vaultData.errorMsg);
        else {
            setMetas(vaultData.mbh_metas);
            setErrorMsg('');
        }
        if (vaultData.userInfoMsg || vaultData.userInfo) {
            setDashboardLoading(false);
            setUserInfo(vaultData.userInfo);
        }
    }, [vaultData]);

    useEffect(() => {
        setDirectPayout((rewards.directAmount + rewards.directPaidAmount).toFixed(3));
        setWatchPayout((rewards.watchAmount + rewards.watchPaidAmount).toFixed(3));
        setAffiliatePayout((rewards.affiliateAmount + rewards.affiliatePaidAmount).toFixed(3));
        setDirectPaidAmount((rewards.directPaidAmount).toFixed(3));
        setWatchPaidAmount((rewards.watchPaidAmount).toFixed(3));
        setAffiliatePaidAmount((rewards.affiliatePaidAmount).toFixed(3));
    }, [rewards]);

    const handleConnectWallet = async () => {
        try {
            setDisclaimerModal(true);
            if (window.ethereum) {
              await window.ethereum.enable();
              setMetamaskConnnected(true);
              window.location.reload();
            } else {
              window.open("https://metamask.io/", "_blank");
            }
          } catch (e) {
            console.log(e);
          }
    };

    return (
        <Animated animationIn="fadeInDown" animationOut="fadeOut" animationInDelay={0}>
            <header id="mbhvault">
                <div className="intro bg-block">
                    <div className="gradient-overlay bg-block"></div>
                    <div className="header-content text-center">
                        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1100}>
                            <h1 className="gradient-text section-title">Your MBH Vault</h1>
                        </Animated>
                        <div className="clearfix"></div>
                        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1200}>
                            <div>
                                {metamaskConnected ? (
                                    <button className="btn btn-sm gradient-btn">
                                        {account &&
                                            <>
                                                {account.substring(0, 5)}...{account.substring(
                                                    account.length - 4
                                                )}
                                            </>
                                        }
                                    </button>
                                ) : (
                                    <>
                                        <p>Connect a wallet that is holding your Meta Bounty Hunters to view your collection</p>
                                        <button className="btn btn-sm gradient-btn" onClick={handleConnectWallet}>CONNECT WALLET</button>
                                    </>
                                )}
                            </div>
                        </Animated>
                    </div>
                    {errorMsg ? (
                        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1000}>
                            <div className="vault-none-container">
                                <p className="text-center vault-error">{errorMsg}</p>
                                <a href="https://marketplace.theouterverse.io/" target="_blank" className="btn btn-lg gradient-btn" rel="noreferrer">
                                    <img src={mbh_icon} alt="opensea-icon" /> Buy on MBH Marketplace
                                </a>
                            </div>
                        </Animated>
                    ) : (
                        account && (
                            <>
                                <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1300}>
                                    <div className="vault-container">
                                        <div className="row">
                                            {metas?.map((e, i) => {
                                                return (
                                                    <div className="col-sm-6 text-center" key={i}>
                                                        <img src={e.normalized_metadata?.image} alt="bounty"></img>
                                                        <h3>{e.name + "#" + e.token_id}</h3>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="text-center">
                                            <a className="btn btn-lg border-btn" style={{ marginTop: "1%" }} href="https://raritysniper.com/meta-bounty-hunters" target="_blank" rel='noreferrer'>
                                                <img src={rarity_icon} alt="rarity-sniper" style={{ border: "0px" }} /> Search MBH Rarity
                                            </a>
                                        </div>
                                    </div>
                                    {
                                        vaultData.iconic_metas?.length > 0 && (
                                            <div className="vault-container">
                                                <h1 className="text-center mb-3">ICONIC MBH</h1>
                                                <div className="row">
                                                    {
                                                        vaultData.iconic_metas.map((e, i) => {
                                                            return (
                                                                <div className="col-sm-6 text-center" key={i}>
                                                                    <video src={e.normalized_metadata?.image} alt="iconic-mbh" autoPlay muted loop style={{ width: "100%" }}></video>
                                                                    <h3>{e.name + "#" + e.token_id}</h3>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        vaultData.baby_metas?.length > 0 && (
                                            <div className="vault-container">
                                                <h1 className="text-center mb-3">Meta Bounty Babies</h1>
                                                <div className="row">
                                                    {
                                                        vaultData?.baby_metas?.map((e, i) => {
                                                            return (
                                                                <div className="col-sm-6 text-center" key={i}>
                                                                    <img src={e.normalized_metadata?.image} alt="bounty-baby" />
                                                                    <h3>{e.name + "#" + e.token_id}</h3>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </Animated>
                            </>
                        )
                    )}
                    {account && (dashboardLoading ? (
                        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1500}>
                            <div className="d-flex align-items-center justify-content-center mt-5">
                                <ReactLoading
                                    type="spin"
                                    color="#0affff"
                                    height={120}
                                    width={120}
                                />
                            </div>
                        </Animated>
                    ) : (
                        userInfo && userInfo != null && (
                            <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDelay={1500}>
                                <div className="container dashboard">
                                    <h1 className="gradient-text section-title text-center">YOUR MBH DASHBOARD</h1>
                                    <Tabs>
                                        <TabList className="text-center p-0">
                                            <Tab className="btn border-btn">MY PROFILE</Tab>
                                            <Tab className="btn border-btn">MY SQUADRON</Tab>
                                            <Tab className="btn border-btn">FREE REWARDS</Tab>
                                            <Tab className="btn border-btn">MY NFTS</Tab>
                                        </TabList>

                                        <TabPanel>
                                            <div className="d-flex align-items-center justify-center">
                                                <div className="dashboard-content">
                                                    <p>Full Name: {userInfo.mdetails.username}</p>
                                                    <p>Call Sign: {userInfo.mdetails.username}</p>
                                                    <p>Referred Call Sign: {userInfo.mdetails.parentusername}</p>
                                                    <p>MetaMaskID: {userInfo.mdetails.metamaskid}</p>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="d-flex align-items-center justify-center">
                                                {
                                                    userInfo.squadron.length > 0 ? (
                                                        <div className="dashboard-content">
                                                            {
                                                                userInfo.squadron.map((item, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <p onClick={() => setShowDetails(prev => {
                                                                                const newState = [...prev];
                                                                                newState[index] = !newState[index];
                                                                                return newState;
                                                                            })} className="squadron-level"><strong>Level {item.level} Enlistment: {item.squadron.length} with {item.squadron.reduce((a, b) => a + (b['nfts'] || 0), 0)} NFTs</strong></p>
                                                                            <div className={showDetails[index] ? 'squadron-detail-box' : 'hidden'}>
                                                                                {
                                                                                    item.squadron.length > 0 && item.squadron.map((child, idx) => {
                                                                                        return (
                                                                                            <p key={idx}>{child.username}: {child.nfts} NFTs</p>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="dashboard-content text-center">
                                                            NO SQUADRON
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="text-center">
                                                <Tabs>
                                                    <TabList className="dashboard-btn-area p-0">
                                                        <Tab className="btn border-btn">TOTAL</Tab>
                                                        <Tab className="btn border-btn">CLAIMED</Tab>
                                                        <Tab className="btn border-btn">CLAIMABLE</Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <div className="dashboard-content" style={{ display: "inline-block" }}>
                                                            {
                                                                directPayout && (
                                                                    <p>DIRECT AMOUNT: {directPayout} ETH</p>
                                                                )}
                                                            {
                                                                affiliatePayout && (
                                                                    <p>AFFILIATE AMOUNT: {affiliatePayout} ETH</p>
                                                                )}
                                                            {
                                                                rewards.iconicDirectAmount + rewards.iconicDirectPaidAmount != 0 && (
                                                                    <p>ICONIC MBH DIRECT AMOUNT: {(rewards.iconicDirectAmount + rewards.iconicDirectPaidAmount).toFixed(3)} ETH</p>
                                                                )
                                                            }
                                                            {
                                                                rewards.iconicAffiliateAmount + rewards.iconicAffiliatePaidAmount != 0 && (
                                                                    <p>ICONIC MBH AFFILIATE AMOUNT: {(rewards.iconicAffiliateAmount + rewards.iconicAffiliatePaidAmount).toFixed(3)} ETH</p>
                                                                )
                                                            }
                                                            {watchPayout && (
                                                                <p>FLEX WATCH AMOUNT: {watchPayout} ETH</p>
                                                            )}
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <div className="dashboard-content" style={{ display: "inline-block" }}>
                                                            <p>DIRECT AMOUNT: {directPaidAmount} ETH</p>
                                                            <p>AFFILIATE AMOUNT: {affiliatePaidAmount} ETH</p>
                                                            {
                                                                rewards.iconicDirectPaidAmount != 0 && (
                                                                    <p>ICONIC MBH DIRECT AMOUNT: {(rewards.iconicDirectPaidAmount).toFixed(3)} ETH</p>
                                                                )
                                                            }
                                                            {
                                                                rewards.iconicAffiliatePaidAmount != 0 && (
                                                                    <p>ICONIC MBH AFFILIATE AMOUNT: {(rewards.iconicAffiliatePaidAmount).toFixed(3)} ETH</p>
                                                                )
                                                            }
                                                            <p>FLEX WATCH AMOUNT: {watchPaidAmount} ETH</p>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <div className="dashboard-content" style={{ display: "inline-block" }}>
                                                            {directPayout && (
                                                                <p>DIRECT AMOUNT : {(directPayout - directPaidAmount).toFixed(3)} ETH</p>
                                                            )}
                                                            {affiliatePayout && (
                                                                <p>AFFILIATE AMOUNT : {(affiliatePayout - affiliatePaidAmount).toFixed(3)} ETH</p>
                                                            )}
                                                            {
                                                                rewards.iconicDirectAmount != 0 && (
                                                                    <p>ICONIC MBH DIRECT AMOUNT: {(rewards.iconicDirectAmount).toFixed(3)} ETH</p>
                                                                )
                                                            }
                                                            {
                                                                rewards.iconicAffiliateAmount != 0 && (
                                                                    <p>ICONIC MBH AFFILIATE AMOUNT: {(rewards.iconicAffiliateAmount).toFixed(3)} ETH</p>
                                                                )
                                                            }
                                                            {watchPayout && (
                                                                <p>FLEX WATCH AMOUNT : {watchPayout - watchPaidAmount} ETH</p>
                                                            )}
                                                        </div>
                                                    </TabPanel>
                                                </Tabs>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="d-flex align-items-center justify-center">
                                                <div className="dashboard-content">
                                                    <p>Total NFTs: {userInfo.mdetails.nfts}</p>
                                                    <p>MetaMaskID: {userInfo.mdetails.metamaskid}</p>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </Animated>
                        )
                    ))
                    }
                    <div className="mt-3 charity-section">
                        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
                        <div className="elfsight-app-fd7a0630-3123-4379-ac13-2096b3247517"></div>
                    </div>
                    <Animated animationIn="bounceInUp" animationOut="fadeOut" isVisible={true} animationInDelay={1300}>
                        <div style={{ marginTop: '2%' }}>
                            <img width="100%" src={header_hunters} alt="header_hunters"></img>
                        </div>
                    </Animated>
                </div>
            </header>
        </Animated>
    );
};
