import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { motion } from "framer-motion";
import Modal from "react-modal";
import opensea_icon from "../../assets/images/opensea_icon.png";
import discord_icon from "../../assets/images/discord_icon.png";
import crateloop from "../../assets/images/cratemint/CRATE_LOOP_GIF.gif";
import logo from "../../assets/images/header_logo.png";
import swal from "sweetalert";
import mbh_head from "../../assets/images/mbh_head.png";
import mbh_icon from "../../assets/images/mbh_icon.png";
import crate1 from "../../assets/images/cratemint/crate1.jpg";
import crate2 from "../../assets/images/cratemint/crate2.jpg";
import crate3 from "../../assets/images/cratemint/crate3.jpg";
import "./cratemint.css";
import { mintCrates } from "../../web3/mint";
import { setMintable, checkMintableFailed, setMintData, getMintDataFailed, changeMintedCounts } from '../../redux/reducers/cratemint';
import usePostAPI from "../../hooks/usePostAPI";

export const CrateMint = (props) => {
  const dispatch = useDispatch();
  const { account, metamaskConnected, setMetamaskConnnected, setDisclaimerModal } = props;
  const mintable = useSelector((state) => state.cratemint.mintable);
  const mintData = useSelector((state) => state.cratemint.mintData);
  const [preLoading, setPreLoading] = useState(false);
  const [selectedCount, setSelectedCount] = useState(1);
  const [mintLoading, setMintLoading] = useState(false);
  const [mintStatus, setMintStatus] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [networkId, setNetworkId] = useState();

  const { fetchData } = usePostAPI();

  useEffect(() => {
    if (metamaskConnected) {
      loadNetwork();
    }
  });

  const loadNetwork = async () => {
    try {
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();
      setNetworkId(networkId);
      window.ethereum.on("chainChanged", (networkId) => {
        setNetworkId(networkId);
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (account) {
      setPreLoading(true);
      fetchData('/checkCratesMintable', { address: account }).then(res => {
        if (res?.success)
          dispatch(setMintable(res));
        else
          dispatch(checkMintableFailed(res));
      });
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      if (mintable?.success != undefined) {
        setPreLoading(false);
      }

      if (mintData?.success == true) {
        const price = mintData.price;
        const tokenAmount = mintData.tokenAmount;
        const timestamp = mintData.timestamp;
        const signature = mintData.signature;

        mintCrates(price, tokenAmount, timestamp, signature, account).then((data) => {
          setMintStatus(data.success);
          if (data.success == false) {
            if (data.type == "estimategas") {
              swal(
                "Sorry",
                `Insufficient funds to mint. Please consider the some gas fee!`,
                "warning"
              );
            } else if (data.type == "mint") {
              swal("Sorry", "Error happened while processing, please try again later", "warning");
            }
          }
          setMintLoading(false);
        })
      } else {
        setMintLoading(false);
      }
    }
  }, [mintData]);

  useEffect(() => {
    if (mintStatus) {
      setViewModal(true);
      dispatch(changeMintedCounts(selectedCount));
      // fetchData('/checkCratesMintable', { address: account }).then(res => {
      //   if (res?.success)
      //     dispatch(setMintable(res));
      //   else
      //     dispatch(checkMintableFailed(res));
      // });
      setMintStatus(false);
    }
  }, [mintStatus]);

  const handleMint = () => {
    setMintLoading(true);
    fetchData('/getCratesMintData', { address: account, count: selectedCount }).then(res => {
      if (res?.success)
        dispatch(setMintData(res));
      else
        dispatch(getMintDataFailed());
    });
  };

  const handleClose = () => {
    setViewModal(false);
  };

  const handleConnectWallet = async () => {
    try {
      setDisclaimerModal(true);
      if (window.ethereum) {
        await window.ethereum.enable();
        setMetamaskConnnected(true);
        window.location.reload();
      } else {
        window.open("https://metamask.io/", "_blank");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {networkId != process.env.REACT_APP_NETWORK_ID && metamaskConnected && (
        <div className="network-err-msg">
          <h2>Please switch to Polygon mainnet</h2>
        </div>
      )}
      <div id="cratemint" className="cratemint">
        <div className="intro bg-block">
          <div className="mint-content">
            <div className="row align-items-center">
              <div className="col-md-6 text-center">
                <motion.div
                  initial={{ opacity: 0, x: -200 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{
                    delay: 0.1,
                    type: "spring",
                    stiffness: 100,
                  }}
                  viewport={{ once: true, amount: 0 }}
                  className="mb-3"
                >
                  <h1>
                    mint your <span>crate</span>
                  </h1>
                  <p className="mt-3">
                    Each missing cargo holds the key to building the
                    powerful MBH spaceships.
                  </p>
                  {preLoading ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                    >
                      <ReactLoading
                        type="spin"
                        color="#0affff"
                        height={120}
                        width={120}
                      />
                    </div>
                  ) : (
                    <>

                      {mintable?.soldout == true ? (
                        <div style={{ marginTop: '20%' }}>
                          <h3 className="mt-5">8,888 BOUNTY BABIES SOLD OUT</h3>
                          <a href="https://opensea.io/collection/boutybabies" target="_blank" className="btn btn-lg gradient-btn mt-5" rel="noreferrer">
                            <img src={opensea_icon} alt="opensea-icon" /> VIEW ON OPENSEA
                          </a>
                          <a href="https://discord.gg/metabountyhunters" target="_blank" className="btn btn-lg gradient-2 mt-5" rel="noreferrer">
                            <img src={discord_icon} alt="discord-icon" /> JOIN OUR DISCORD
                          </a>
                        </div>
                      ) : (
                        <div className="mint-box">
                          <div className="absolute-btn">
                            {metamaskConnected ? (
                              <>
                                {mintable?.mint_open == false ? (
                                  <div className='text-center'><h5>Free Mint is not available now.</h5></div>
                                ) : (
                                  <>
                                    <div className="input-box">
                                      <h5>MBH Iconics</h5>
                                      <h6>{mintable?.iconic?.count || 0} x {mintable?.iconic?.limit || 0}</h6>
                                    </div>
                                    <div className="input-box">
                                      <h5>MBH Couple</h5>
                                      <span>
                                        <h6>{mintable?.pair?.count || 0} x {mintable?.pair?.limit || 0}</h6>
                                      </span>
                                    </div>
                                    <div className="input-box">
                                      <h5>Hunter</h5>
                                      <span>
                                        <h6>{mintable?.hunter?.count || 0} x {mintable?.hunter?.limit || 0}</h6>
                                      </span>
                                    </div>
                                    <div className="input-box">
                                      <h5>Huntress</h5>
                                      <span>
                                        <h6>{mintable?.huntress?.count || 0} x {mintable?.huntress?.limit || 0}</h6>
                                      </span>
                                    </div>
                                    <div className="input-box">
                                      <h5>MBH Family</h5>
                                      <span>
                                        <h6>{mintable?.family?.count || 0} x {mintable?.family?.limit || 0}</h6>
                                        <h6></h6>
                                      </span>
                                    </div>
                                    <div className="input-box">
                                      <h5>Top Secret Agent</h5>
                                      <span>
                                        <h6>{mintable?.top?.count || 0} x {mintable?.top?.limit || 0}</h6>
                                      </span>
                                    </div>
                                    <div className="input-box">
                                      <h5>Secret Agent</h5>
                                      <span>
                                        <h6>{mintable?.secret?.count || 0} x {mintable?.secret?.limit || 0}</h6>
                                      </span>
                                    </div>
                                    <div className="input-box">
                                      <h5>Minted / Available</h5>
                                      <span>
                                        <h6>{mintable?.minted || 0} / {parseInt(mintable?.count) + parseInt(mintable?.minted) || 0}</h6>
                                      </span>
                                    </div>
                                    <div className="input-box">
                                      <h5>Amount</h5>
                                      <div className="d-flex">
                                        <span className="counter-operator" onClick={() => setSelectedCount((e) => mintable?.count > 0 && e > 1 ? e - 1 : e)} >-</span>
                                        <input value={selectedCount} className="counter-input" onChange={(e) => setSelectedCount(mintable?.count > 0 ? (e.target.value < 1 ? 1 : e.target.value > 150 ? Math.min(150, mintable?.count) : Math.min(e.target.value, mintable?.count)) : 1)} />
                                        <span className="counter-operator" onClick={() => setSelectedCount((e) => mintable?.count > 0 && e < 150 ? Math.min(e + 1, mintable?.count) : e)} >+</span>
                                      </div>
                                      <button className="btn-max" onClick={() => setSelectedCount((e) => Math.min(150, mintable?.count))}>max</button>
                                    </div>
                                    <div className="input-box">
                                      <h5>Total</h5>
                                      <span>
                                        <h6>{(parseFloat(mintable?.price ? mintable.price : 0) * selectedCount).toFixed(2)} MATIC</h6>
                                      </span>
                                    </div>

                                    <div className="text-center failed-msg">{mintable?.failed_message}</div>
                                    {/* {networkId != process.env.REACT_APP_NETWORK_ID && (
                                    <div className="text-center">please switch network to polygon.</div>
                                  )} */}
                                  </>
                                )}
                                <div className="button-box">
                                  <div className="button-top-line"></div>
                                  <button
                                    className="btn btn-lg gradient-2"
                                    disabled={mintLoading || mintable?.count == 0 || mintable?.failed_message || networkId != +process.env.REACT_APP_NETWORK_ID}
                                    onClick={() => handleMint()}
                                  >
                                    {mintLoading ? (
                                      <span><ReactLoading type="spin" color="#d2dcf9" width={50} height={50} /></span>
                                    ) : (
                                      <>
                                        <img src={mbh_head} />
                                        {mintLoading || mintable?.count == 0 || mintable?.failed_message || networkId != +process.env.REACT_APP_NETWORK_ID ? 'MINT UNAVAILABLE' : 'MINT NOW'}
                                      </>
                                    )}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="button-box">
                                <div className="button-top-line"></div>
                                <button className="btn btn-lg gradient-2" onClick={() => handleConnectWallet()}>
                                  <img src={mbh_head} />
                                  CONNECT WALLET
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </motion.div>
                {/* )} */}

                {/* )} */}
              </div>
              <div className="col-md-6 crate-preview">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{
                    delay: 0.1,
                    type: "spring",
                    stiffness: 100,
                  }}
                  viewport={{ once: true, amount: 0 }}
                >
                  <img src={crateloop} alt="preview-crate" />
                </motion.div>
              </div>
            </div>
            {/* {mintable.soldout && (
                    <div>
                    </div>
                  )} */}
            <div className="text-center mt-3 crate-content">
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.5,
                  type: "spring",
                  stiffness: 100,
                }}
                viewport={{ once: true, amount: 0 }}
              >
                <p className="crate-faq">
                  How to switch from ethereum network to polygon network to mint crates? Check <a href="https://theouterverse.io/howtoswitchtopolygon.mp4" target="_blank" rel="noopener noreferrer">here</a>
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.5,
                  type: "spring",
                  stiffness: 100,
                }}
                viewport={{ once: true, amount: 0 }}
              >
                <img src={mbh_icon} className="" alt="mbh_logo"></img>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.1,
                  type: "spring",
                  stiffness: 100,
                }}
                viewport={{ once: true, amount: 0 }}
              >
                <h1>
                  calling all holders<br />
                  <span>your help is needed</span>
                </h1>
                <p>
                  In a galaxy where a missing cargo holds the key to building
                  powerful Spaceships capable of saving the galaxy, only the
                  Meta Bounty Hunters/Huntresses and "Bounty Babies" possess the
                  ability to locate the crates. These crates unlock ship parts,
                  and their rarity—common, rare, or very rare—is completely
                  random. Commons provide 1 part, rares offer 2, and very rare
                  offers 3 parts.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="mission">
          <div className="mission-clear">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                delay: 0.1,
                type: "spring",
                stiffness: 100,
              }}
              viewport={{ once: true, amount: 0 }}
            >
              <h1>
                the mission is <span>clear</span>
              </h1>
              <p>
                Gather the crates, unlock the parts, and prepare to build the
                spaceships that can travel faster than light and defend the
                galaxy. It's a journey filled with adventure and uncertainty,
                but the fate of the known galaxy rests in your hands.
              </p>
            </motion.div>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-4 crate-img">
                <motion.div
                  initial={{ opacity: 0, y: -100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: 0.4,
                    duration: 0.4,
                    type: "spring",
                    stiffness: 100,
                  }}
                  viewport={{ once: true, amount: 0 }}
                >
                  <img src={crate1} alt="crate1" />
                </motion.div>
              </div>
              <div className="col-md-4 crate-img mt-crate-img" >
                <motion.div
                  initial={{ opacity: 0, y: -100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: 0.8,
                    duration: 0.4,
                    type: "spring",
                    stiffness: 100,
                  }}
                  viewport={{ once: true, amount: 0 }}
                >
                  <img src={crate2} alt="crate2" />
                </motion.div>
              </div>
              <div className="col-md-4 crate-img">
                <motion.div
                  initial={{ opacity: 0, y: -100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: 1.2,
                    duration: 0.4,
                    type: "spring",
                    stiffness: 100,
                  }}
                  viewport={{ once: true, amount: 0 }}
                >
                  <img src={crate3} alt="crate3" />
                </motion.div>
              </div>
            </div>

            {!metamaskConnected && (
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{
                  delay: 1.2,
                  duration: 0.3,
                  type: "spring",
                  stiffness: 100,
                }}
                viewport={{ once: true, amount: 0 }}
              >
                <div className="d-flex justify-content-center mt-4">
                  <button className="btn btn-lg gradient-2">
                    <img src={mbh_head} />
                    CONNECT WALLET
                  </button>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
      <Modal
        appElement={document.getElementById("root")}
        isOpen={viewModal}
        onRequestClose={handleClose}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "overlay-after",
          beforeClose: "overlay-before",
        }}
        className={{
          base: "content-base",
          afterOpen: "content-after mint-success-modal",
          beforeClose: "content-before",
        }}
        closeTimeoutMS={500}
      >
        <div className="mint-logo">
          <Link to="/" rel="nofollow">
            <img src={logo} alt="mbh-logo" />
          </Link>
        </div>
        <div className="close">
          <i className="fa close-icon" onClick={handleClose}>
            &#xf00d;
          </i>
        </div>
        <div className="success-msg">
          <h1>CONGRATULATIONS!</h1>
          <p>
            YOU HAVE SUCCESSFULLY MINTED YOUR <strong>CRATE</strong>!
          </p>
        </div>
        <div className="success-links">
          <a
            href="https://opensea.io/collection/mbhcrates"
            target="_blank"
            className="btn btn-lg gradient-btn mt-1"
            rel="noreferrer"
          >
            <img src={opensea_icon} alt="opensea-icon" /> VIEW ON OPENSEA
          </a>
          <a
            href="https://discord.gg/metabountyhunters"
            target="_blank"
            className="btn btn-lg gradient-2 mt-1"
            rel="noreferrer"
          >
            <img src={discord_icon} alt="discord-icon" /> JOIN OUR DISCORD
          </a>
        </div>
      </Modal>
    </>
  );
};
