import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mbh: {
        mbh_metas: [],
        iconic_metas: [],
        baby_metas: [],
        userInfo: null,
        errorMsg: null,
        userInfoMsg: null
    },
    mla: {
        metadata_mla1: [],
        metadata_mla2: [],
        userInfo: null,
        errorMsg: null,
        userInfoMsg: null,
    },
    huntress: {
        metadata: [],
        userInfo: null,
        errorMsg: null,
        userInfoMsg: null,
    },
    mbc: {
        metas: [],
        errorMsg: null
    },
};

export const vaultSlice = createSlice({
    name: 'vault',
    initialState,
    reducers: {
        setMBHMetadata(state, action) {
            state.mbh.mbh_metas = action.payload.mbh_metas;
            state.mbh.iconic_metas = action.payload.iconic_metas;
            state.mbh.baby_metas = action.payload.baby_metas;
            state.mbh.errorMsg = "";
        },
        setMBHUserInfo(state, action) {
            state.mbh.userInfo = action.payload.userInfo;
        },
        getMBHMetadataFailed(state, action) {
            state.mbh.mbh_metas = [];
            state.mbh.iconic_metas = [];
            state.mbh.baby_metas = [];
            state.mbh.errorMsg = "Haven't joined our empire yet? Get your Meta Bounty Hunter today.";
        },
        getMBHUserInfoFailed(state, action) {
            state.mbh.userInfo = null;
            state.mbh.userInfoMsg = action.payload?.message;
        },
        setMLAMetadata(state, action) {
            state.mla.metadata_mla1 = action.payload.metadata_mla1;
            state.mla.metadata_mla2 = action.payload.metadata_mla2;
            state.mla.errorMsg = "";
        },
        setMLAUserInfo(state, action) {
            state.mla.userInfo = action.payload.userInfo;
        },
        getMLAMetadataFailed(state, action) {
            state.mla.metadata_mla1 = [];
            state.mla.metadata_mla2 = [];
            state.mla.errorMsg = "Haven't joined our empire yet? Get your Agent today.";
        },
        getMLAUserInfoFailed(state, action) {
            state.mla.userInfo = null;
            state.mla.userInfoMsg = action.payload?.message;
        },
        setHuntressMetadata(state, action) {
            state.huntress.metadata = action.payload.metadata;
            state.huntress.errorMsg = "";
        },
        setHuntressUserInfo(state, action) {
            state.huntress.userInfo = action.payload.userInfo;
        },
        getHuntressMetadataFailed(state, action) {
            state.huntress.metadata = [];
            state.huntress.errorMsg = "Haven't joined our club yet?<br />Get your Meta Bounty Huntresses NFT today.";
        },
        getHuntressUserInfoFailed(state, action) {
            state.huntress.userInfo = null;
            state.huntress.userInfoMsg = action.payload?.message;
        },
        setMBCMetadata(state, action) {
            state.mbc = { metas: action.payload.metas, errorMsg: "" };
        },
        getMBCMetadataFailed(state, action) {
            state.mbc = { metas: [], errorMsg: "Haven't joined our empire yet? Get your Meta Bounty Crate today." };
        },
    }
});

export const {
    setMBHMetadata,
    setMBHUserInfo,
    getMBHMetadataFailed,
    getMBHUserInfoFailed,
    setMLAMetadata,
    setMLAUserInfo,
    getMLAMetadataFailed,
    getMLAUserInfoFailed,
    setHuntressMetadata,
    setHuntressUserInfo,
    getHuntressMetadataFailed,
    getHuntressUserInfoFailed,
    setMBCMetadata,
    getMBCMetadataFailed
} = vaultSlice.actions;

export default vaultSlice.reducer;