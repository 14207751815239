import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import Loader from "../components/Loader";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { MBCVaultMain } from "../components/vault/mbc";

const MBCVault = (props) => {
    const { metamaskConnected, account, setMetamaskConnnected, setDisclaimerModal } = props;

    const [Loaded, setLoaded] = useState(false);
    useEffect(() => {
        const onPageLoad = () => {
            setLoaded(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const [active, setActive] = useState(false);
    useEffect(() => {
        const onScroll = () => window.scrollY > 100 ? setActive(true) : setActive(false);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>
            <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence>
            <Header doAnim={false} isActive={active} />
            <MBCVaultMain metamaskConnected={metamaskConnected} setMetamaskConnnected={setMetamaskConnnected} account={account} setDisclaimerModal={setDisclaimerModal} />
            <div className="scroller h-auto">
                <Footer />
            </div>
        </>
    )
}

export default MBCVault;