import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Header } from "../components/header";
import Loader from "../components/Loader";
import { Home } from "../components/home";
import { Universe } from "../components/universe";
import { Roadmap } from "../components/roadmap";
import { Crate } from "../components/crate";
import { Footer } from "../components/footer";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
const keyCodes = {
    UP: 38,
    DOWN: 40
}

const slideHashes = ['#home', '#universe', '#roadmap', '#crate'];

const Outerverse = () => {
    const [Loaded, setLoaded] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [pageHeight, setPageHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState();
    const [touchPos, setTouchPos] = useState(0);
    const [headerActive, setHeaderActive] = useState(false);

    const container = useRef();

    const currentSlideRef = useRef(currentSlide);
    currentSlideRef.current = currentSlide;

    const isAnimatingRef = useRef(isAnimating);
    isAnimatingRef.current = isAnimating;

    const pageHeightRef = useRef(pageHeight);
    pageHeightRef.current = pageHeight;

    const innerWidthRef = useRef(innerWidth);
    innerWidthRef.current = innerWidth;

    const touchPosRef = useRef(touchPos);
    touchPosRef.current = touchPos;

    const location = useLocation();

    useEffect(() => {
        setInnerWidth(window.innerWidth);
        gsap.set(container.current, { overflowY: 'scroll' });

        const onPageLoad = () => {
            setLoaded(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const slides = document.getElementsByClassName("section");

    useEffect(() => {
        if (location.hash && slideHashes.includes(location.hash))
            goToSlide(slideHashes.indexOf(location.hash));
        else
            goToSlide(0);
        window.addEventListener("resize", onResize);
        window.addEventListener("mousewheel", onMouseWheel, { passive: false });
        window.addEventListener("DOMMouseScroll", onMouseWheel, { passive: false });
        window.addEventListener("wheel", onMouseWheel, { passive: false });
        window.addEventListener("mousedown", onClick, { passive: false });
        window.addEventListener("touchmove", onTouchMove, { passive: false });
        window.addEventListener("touchstart", onTouchStart);
        document.addEventListener("keydown", onKeyDown);

        return () => {
            window.removeEventListener("resize", onResize);
            document.removeEventListener("keydown", onKeyDown);
            window.removeEventListener("mousewheel", onMouseWheel);
            window.removeEventListener("DOMMouseScroll", onMouseWheel);
            window.removeEventListener("wheel", onMouseWheel);
            window.removeEventListener("touchmove", onMouseWheel);
            window.addEventListener("touchstart", onTouchStart);
        }
    }, [])

    /*
    *   Internal functions
    * */

    /*
    *   Getting the pressed key. Only if it's up or down arrow, we go to prev or next slide and prevent default behaviour
    *   This way, if there's text input, the user is still able to fill it
    * */
    function onKeyDown(event) {
        const PRESSED_KEY = event.keyCode;

        if (PRESSED_KEY === keyCodes.UP) {
            goToPrevSlide();
            // event.preventDefault();
        }
        else if (PRESSED_KEY === keyCodes.DOWN) {
            // event.preventDefault();
            goToNextSlide();
        }

    }

    function onClick(event) {
        if (event.which == 2) {
            event.preventDefault();
        }
    }

    function onTouchStart(event) {
        setTouchPos(event.changedTouches[0].clientY);
    }

    function onTouchMove(event) {
        let newTouchPos = event.changedTouches[0].clientY;
        if (isAnimatingRef.current) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        } else if (newTouchPos > touchPosRef.current) {
            const slided = moveSlide(0);
            if (slided || isAnimatingRef.current) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        }
        if (newTouchPos < touchPosRef.current) {
            const slided = moveSlide(1);
            if (slided || isAnimatingRef.current) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        }
    }
    /*
    *   When user scrolls with the mouse, we have to change slides
    * */
    function onMouseWheel(event) {
        const delta = event.wheelDelta / 30 || -event.detail;
        if (isAnimatingRef.current) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        } else if (delta < -1) {
            const slided = moveSlide(1);
            if (slided || isAnimatingRef.current) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        }
        else if (delta > 1) {
            const slided = moveSlide(0);
            if (slided || isAnimatingRef.current) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        }
    }

    /*
    *   If there's a previous slide, slide to it
    * */
    function goToPrevSlide() {
        if (currentSlideRef.current - 1 >= 0) {
            goToSlide(currentSlideRef.current - 1);
        }
    }

    /*
    *   If there's a next slide, slide to it
    * */
    const goToNextSlide = () => {
        if (currentSlideRef.current + 1 < 4) {
            goToSlide(currentSlideRef.current + 1);
        }
    };

    /*
    *   Actual transition between slides
    * */
    const goToSlide = (target) => {
        if (!isAnimatingRef.current && target >= 0) {
            //setting animating flag to true
            setIsAnimating(true);
            setCurrentSlide(target);

            const targetID = "#" + slides[target].id;

            gsap.to('.scroller', {
                duration: 1.3,
                scrollTo: targetID,
                ease: 'Power1.easeInOut',
                onComplete: onSlideChangeEnd
            })

        }
    }

    const moveSlide = (direction) => {
        if (!isAnimatingRef.current && direction === 1) {
            if (currentSlideRef.current < 3) {
                if (slides[currentSlideRef.current].getBoundingClientRect().bottom <= window.innerHeight) {
                    setIsAnimating(true);
                    const targetID = "#" + slides[currentSlideRef.current + 1].id;
                    setCurrentSlide(currentSlideRef.current + 1);
                    gsap.to('.scroller', {
                        duration: 1.3,
                        scrollTo: targetID,
                        ease: 'Power1.easeInOut',
                        onUpdate: checkProgress,
                        onComplete: onSlideChangeEnd
                    });
                    return true;
                }
            }
        } else if (!isAnimatingRef.current && direction === 0) {
            if (currentSlideRef.current >= 1) {
                if (slides[currentSlideRef.current].getBoundingClientRect().top >= 0) {
                    setIsAnimating(true);
                    const targetID = "#" + slides[currentSlideRef.current - 1].id;
                    setCurrentSlide(currentSlideRef.current - 1);
                    gsap.to('.scroller', {
                        duration: 1.3,
                        scrollTo: targetID,
                        ease: 'Power1.easeInOut',
                        onUpdate: checkProgress,
                        onComplete: onSlideChangeEnd
                    });
                    return true;
                }
            }
        }

        const scrollTop = slides[currentSlideRef.current]?.getBoundingClientRect().top;
        if(scrollTop < -100)
            setHeaderActive(true);
        return false;
    }

    function checkProgress() {
        //  gsap.config({autoKillThreshold:2});
        gsap.set(container.current, {
            scrollTo: { autoKill: false },
            overwrite: "true"
        });
    }

    /*
    *   Once the sliding is finished, we need to restore "isAnimating" flag.
    *   You can also do other things in this function, such as changing page title
    * */
    function onSlideChangeEnd() {
        setTimeout(() => {
            setIsAnimating(false);
            if (slides[currentSlideRef.current] && container.current) {
                const scrollTop = slides[currentSlideRef.current]?.getBoundingClientRect().top;
                if (scrollTop != 0)
                    gsap.to(".scroller", { scrollTo: { y: container.current.scrollTop + scrollTop, ease: 'Power1.easeInOut', duration: 0.3 } });
                console.log('end', scrollTop);
            }
        }, 500);
        setHeaderActive(false);
    }

    /*
    *   When user resize it's browser we need to know the new height, so we can properly align the current slide
    * */
    function onResize(event) {

        //This will give us the new height of the window
        const newPageHeight = window.innerHeight;

        /*
        *   If the new height is different from the old height ( the browser is resized vertically ), the slides are resized
        * */
        if (pageHeightRef.current !== newPageHeight) {
            setPageHeight(newPageHeight);

            //This can be done via CSS only, but fails into some old browsers, so I prefer to set height via JS
            // gsap.set([container.current, slides], { height: newPageHeight + "px" });

            //The current slide should be always on the top
            gsap.set(container.current, { scrollTo: { y: newPageHeight * currentSlideRef.current } });
        }
    }

    return (
        <div>
            <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence>
            <Header goToSlide={goToSlide} doAnim={true} isActive={headerActive} />
            <div className='scroller' ref={container}>
                <Home goToSlide={goToSlide} setCurrentSlide={setCurrentSlide} />
                <Universe setCurrentSlide={setCurrentSlide} />
                <Roadmap setCurrentSlide={setCurrentSlide} />
                <Crate setCurrentSlide={setCurrentSlide} />
                <Footer />
            </div>
        </div>
    )
}

export default Outerverse;