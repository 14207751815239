import { useLayoutEffect } from "react";
import gsap from "gsap";
import "./crate.css";
import crate1 from "../../assets/images/crate/crate1.jpg";
import crate2 from "../../assets/images/crate/crate2.jpg";
import crate3 from "../../assets/images/crate/crate3.jpg";
import mbhu from "../../assets/images/crate/mbhunter-huntresses.png";
import mbb from "../../assets/images/crate/metabountybabies.png";
import mbhiconics from "../../assets/images/crate/mbhiconics.png";
import mla from "../../assets/images/crate/metalabsagents.png";
import earth from "../../assets/images/crate/planet-earth.png";
import venus from "../../assets/images/crate/planet-venus.png";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export const Crate = () => {
  useLayoutEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: ".crate-title",
        scroller: ".scroller",
      },
    })
      .fromTo(
        document.querySelectorAll(".crate-title span"),
        { opacity: 0 },
        { duration: 1, ease: "power4.inOut", opacity: 1 },
        0)
      .fromTo(
        document.querySelectorAll(".crate-planet-venus"),
        { opacity: 0, scale: 0 },
        { duration: 1, ease: "power4.inOut", opacity: 1, scale: 1 },
        0.5)
      .fromTo(
        document.querySelectorAll(".crate-title h1"),
        { scale: 0, opacity: 0 },
        { duration: 1, ease: "power4.out", scale: 1, opacity: 1 },
        0.5)
      .fromTo(
        document.querySelectorAll(".crate-title p"),
        { opacity: 0, y: 50 },
        { duration: 1, ease: "power4.out", opacity: 1, y: 0 },
        1.5
      )
      .fromTo(
        document.querySelectorAll(".crate-one"),
        { opacity: 0, y: -100, x: 100 },
        { duration: 0.5, ease: "power4.out", opacity: 1, y: 0, x: 0, stagger: { amount: 1 } },
        2
      );

    gsap.timeline({
      scrollTrigger: {
        trigger: ".essential-one",
        scroller: ".scroller",
      },
    })
      .fromTo(
        document.querySelectorAll(".why-essential"),
        { opacity: 0 },
        { duration: 1, ease: "power4.inOut", opacity: 1 },
        0)
      .fromTo(
        document.querySelectorAll(".crate-planet-earth"),
        { opacity: 0, scale: 0 },
        { duration: 1, ease: "power4.inOut", opacity: 1, scale: 1 },
        0.5)
      .fromTo(
        document.querySelectorAll(".essential-one"),
        { opacity: 0, y: -100, x: -100 },
        { duration: 0.5, ease: "power4.out", opacity: 1, y: 0, x: 0, stagger: { amount: 1 } },
        0.5
      );

    gsap.timeline({
      scrollTrigger: {
        trigger: ".quest-pane",
        scroller: ".scroller",
      },
    })
      .fromTo(
        document.querySelectorAll(".quest-pane"),
        { opacity: 0, scale: 0 },
        { duration: 2, ease: "power4.inOut", opacity: 1, scale: 1 },
        0);

    gsap.timeline({
      scrollTrigger: {
        trigger: ".disclaimer-content",
        scroller: ".scroller",
      },
    })
      .fromTo(
        document.querySelectorAll(".disclaimer h1"),
        { opacity: 0, y: -100 },
        { duration: 1.5, ease: "power4.inOut", opacity: 1, y: 0 },
        0)
      .fromTo(
        document.querySelectorAll(".disclaimer-content p"),
        { opacity: 0, x: 100 },
        { opacity: 1, x: 0, stagger: { amount: 4 }, ease: "power4.out" },
        1
      );    
  }, []);

  return (
    <div className="section crate" id="crate">
      <img src={earth} className="crate-planet-earth" />
      <img src={venus} className="crate-planet-venus" />

      <div className="container h-100 justify-content-center">
        <div className="crate-title">
          <span>THE MISSION</span>
          <h1>RETRIEVE THE MBH CRATES</h1>
          <p>These aren't just any crates. They hold the secrets to constructing the most advanced spaceship ever conceived, designed to travel through the multiverse!</p>
        </div>

        <div className="row">
          <div className="col-md-4 crate-one">
            <img src={crate1} className="crate-img" />
          </div>
          <div className="col-md-4 crate-one">
            <img src={crate2} className="crate-img" />
          </div>
          <div className="col-md-4 crate-one">
            <img src={crate3} className="crate-img" />
          </div>
        </div>

        <div className="why-essential">
          <h1>WHY YOU'RE ESSENTIAL</h1>
          <p>After an exhilarating journey that spanned galaxies and tested the mettle of Captain Suni, the brave Meta Bounty Hunters, the indomitable Huntress, the ingenious Bounty Babies, and the tech-savvy MLAgents, a new chapter has begun.</p>
        </div>

        <div className="row">
          <div className="col-md-6 essential-one">
            <img src={mbhu} />
            <h5>META BOUNTY HUNTERS/<br />HUNTRESSES</h5>
            <p>Fearless warriors who lead the charge in collecting the crates, facing challenges head-on.</p>
          </div>

          <div className="col-md-6 essential-one">
            <img src={mbb} />
            <h5>META BOUNTY<br />BABIES</h5>
            <p>With their genius minds, they'll assemble the 16 critical pieces that make up the spaceship.</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 essential-one">
            <img src={mla} />
            <h5>FIELD/SECRET<br />AGENTS</h5>
            <p>These tech wizards provide the technological know-how to navigate the uncharted territories of the multiverse.</p>
          </div>

          <div className="col-md-6 essential-one">
            <img src={mbhiconics} />
            <h5>META BOUNTY HUNTERS<br />3D ICONICS</h5>
            <p>A coalition of 400 fearless protectors, forming alliances and dominating the NFT Galaxy.</p>
          </div>
        </div>

        <div className="quest-pane">
          <span>ARE YOU READY?</span>
          <h1>TIME FOR YOUR QUEST</h1>
          <p>The stakes are high, and the rewards are legendary. Will you join the ranks of brave explorers and become a part of this incredible adventure?</p>
        </div>
      </div>

      <div className="container disclaimer">
        <h1 className="mt-3 text-center">Disclaimer</h1>
        <div className="disclaimer-content text-left mt-3">
          <p><strong>General.</strong>  This document describes Non Fungible Tokens and the Meta Bounty Hunter Community (MBHC PTE LTD) and the current vision for the architecture of the community post mint. The sole financial transaction between the purchaser and Community is limited to the initial mint and purchase of the Non Fungible Token (NFT) on the ERC 721 blockchain. There is no financial contract or implied relationship whether financial or other between the purchaser and MBHC or any of the community founders or representatives.</p>

          <p>The contribution or any use of proceeds is at the sole election of MBHC and its choice in use of proceeds from the sale of the NFT collection. Any potential benefit or how it is distributed is also at the sole discretion of the MBHC. There is no representation of any amount as it relates to MBHC’s choice in use of proceeds from the sale.</p>

          <p>Each MBHC NFT comes with access to Utilities. These Utilities are active for a period of 4 years from the public mint date. Any utility must be claimed within six months of becoming eligible or they will expire and be forfeited.  NFT utilities are nonrefundable and nonexchangeable. Utilities benefits will continue to be active for no longer than four years from the initial mint date but may be terminated earlier by MBHC at its discretion. Any unclaimed utility benefit will expire and become unredeemable four years after the public mint date. Utility benefits will be immediately forfeited without regards to any remaining time on any six month periods when that four year period has completed. All utility benefits are free and distributed at the discretion of MBHC and no liability can be attributed to MBHC or its partners for failure to offer any specific Utility to an NFT Holder.</p>

          <p><strong>Disclaimer of Liability.</strong> MBHC does not make or purport to make, and hereby disclaims, any representation, warranty, or guarantee of any type or nature whatsoever (including those that are implied) to any person or entity, including, without limitation, any representation, warranty, or guarantee arising out of or relating to the truth, accuracy, and precision of any information in this document and the MBHC ecosystem, or MBHC future platform. To the maximum extent permitted by applicable law, regulations, and rules, MBHC will not be liable for any indirect, special, incidental, consequential, or other losses of any kind, in tort, contract, or otherwise (including, without limitation, loss of revenue, income, or profits, and loss of use or data) arising out of or relating to any acceptance of or reliance on this document or any part thereof by you.</p>

          <p>This document does not obligate any party to enter into any contract or binding legal commitment or to accept any form of payment for any purpose. Any agreement with MBHC for the purchase of an NFT from the collection will be governed by the terms of use and privacy policy of that agreement, which will prevail over this document.</p>

          <p><strong>Not A Securities Offering.</strong> The MBH or any MBHC projects are a stand alone collection and the purchase of such is an independent transaction and has no relationship to the choice in use of proceeds by MBHC as it relates to their choice in supporting the vision and roadmap of the MBHC Community. This document or any part of it does not constitute a prospectus or offer document of any type or nature and is not intended to constitute an offer of securities or a solicitation for investment insecurities in any jurisdiction.</p>

          <p><strong>Risks and Uncertainties.</strong> Prospective purchasers of MBHC NFT’s or MBHC services should carefully consider and evaluate all risks and uncertainties associated with the digital NFT market, Meta Bounty Hunters, the MBHC ecosystem, MBHC Platform as the digital asset market is a volatile and risky market. These risks and uncertainties may include, without limitation, the following: risks relating to the value of the MBHC NFT risk relating to competitive conditions; risks relating to blockchain technology and software, including delays, interruptions, errors, losses, advances, and changes; security risks to your access and use of a digital wallet and blockchain applications; risk relating to platform development and implementation of business strategies, including, reliance on its personnel and third-party service providers; political or government risk, including changes to applicable laws, regulations, and rules, and enforcement actions. If any such risks and uncertainties arises, such events may materially and adversely affect MBHC, and you may lose all or part of the value of the MBHCs NFTs.</p>

          <p><strong>Non-advisory.</strong> This document does not constitute any legal, tax, regulatory, financial, accounting, or other advice, and is not intended to provide the sole basis for any evaluation of Meta Bounty Hunters, MBHC NFT’s, the Metabounty Hunters ecosystem, or the MBHC community. Before acquiring MBHC NFTs a prospective purchaser should consult legal, investment, tax, accounting, and other advisors to determine the potential benefits, burdens, and other consequences of such a transaction.</p>
        </div>
      </div>
    </div>
  );
};
