import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HashLink } from "react-router-hash-link";
import sub_logo from '../../assets/images/mla_sub_logo.png';
import button_logo from "../../assets/images/mla_button_logo.png";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./vault.css";
import { setMLAMetadata, setMLAUserInfo, getMLAMetadataFailed, getMLAUserInfoFailed } from '../../redux/reducers/vault';
import { setMLARewards, checkMLARewardsFailed } from "../../redux/reducers/rewards";
import usePostAPI from "../../hooks/usePostAPI";
import ReactLoading from "react-loading";

export const MLAVaultMain = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected, setDisclaimerModal } = props;
  const vaultData = useSelector((state) => state.vault.mla);
  const rewards = useSelector((state) => state.rewards.mla);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [directPayout, setDirectPayout] = useState(0);
  const [affiliatePayout, setAffiliatePayout] = useState(0);
  const [directPaidAmount, setDirectPaidAmount] = useState(0);
  const [affiliatePaidAmount, setAffiliatePaidAmount] = useState(0);

  const { fetchData } = usePostAPI();

  useEffect(() => {
    if (account) {
        fetchData('/mla/getMetadata', { address: account }).then(res => {
            if (res?.success)
                dispatch(setMLAMetadata({
                    metadata_mla1: res.metas_mla1,
                    metadata_mla2: res.metas_mla2
                }));
            else
                dispatch(getMLAMetadataFailed());
        });
        fetchData('/mla/getUserInfo', { address: account }).then(res => {
            if (res?.success)
                dispatch(setMLAUserInfo({
                    userInfo: res.userInfo
                }));
            else
                dispatch(getMLAUserInfoFailed({ ...res }));
        });
        fetchData('/mla/checkRewards', { address: account }).then(res => {
            if (res?.success)
                dispatch(setMLARewards({
                    ...res
                }));
            else
                dispatch(checkMLARewardsFailed({ ...res }));
        });
    }
  }, [account]);

  useEffect(() => {
    if(vaultData?.errorMsg && vaultData.errorMsg != '')
      setErrorMsg(vaultData.errorMsg);

    if(vaultData?.userInfoMsg || vaultData?.userInfo) {
      setDashboardLoading(false);
      setUserInfo(vaultData.userInfo);
    }
  }, [vaultData]);

  useEffect(() => {
    setDirectPayout((rewards.directAmount + rewards.directPaidAmount).toFixed(3));
    setAffiliatePayout((rewards.affiliateAmount + rewards.affiliatePaidAmount).toFixed(3));
    setDirectPaidAmount((rewards.directPaidAmount).toFixed(3));
    setAffiliatePaidAmount((rewards.affiliatePaidAmount).toFixed(3));
  }, [rewards]);

  const handleConnectWallet = async () => {
    try {
      setDisclaimerModal(true);
      if (window.ethereum) {
        await window.ethereum.enable();
        setMetamaskConnnected(true);
        window.location.reload();
      } else {
        window.open("https://metamask.io/", "_blank");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div id="mlavault">
      <div className="intro bg-block">
        <div className="section-title text-center">
            <img src={sub_logo} alt="who-sublogo"></img>
            <h1>MLAGENT VAULT</h1>
            <p className="vault-description">Welcome MLAgent!</p>
        </div>
        <div className="header-content text-center mb-4">
          <div className="clearfix"></div>
          <div>
            {metamaskConnected ? (
              <button className="btn btn-border gradient connect-btn mt-3">
                {account &&
                  <span>
                    {account.substring(0, 5)}...{account.substring(
                      account.length - 4
                    )}
                  </span>
                }
              </button>
            ) : (
              <>
                <p className="vault-description">Connect the wallet that is holding your MLAgent NFT to view your collection, free rewards and team.</p>
                <button className="btn btn-border gradient connect-btn mt-3" onClick={handleConnectWallet}><span>CONNECT WALLET</span></button>
              </>
            )}
          </div>
        </div>
        {errorMsg? (
            <div className="container text-center">
              <p className="text-center vault-error mt-2">{errorMsg}</p>
              <div className="gap-2 d-flex align-items-center justify-content-center btn-block">
                <HashLink smooth to="https://mlagents.io/mintCollection1#" className="btn btn-border gradient mt-3 mint-button blue"><img src={button_logo} alt="button-icon" />MINT FIELD AGENT</HashLink>
                <HashLink smooth to="https://mlagents.io/mintCollection2#" className="btn btn-border gradient mt-3 mint-button"><img src={button_logo} alt="button-icon" />MINT SECRET AGENT</HashLink>
                </div>
            </div>
            ) : (
            account&&(
              <div className="container">
                <div className="row d-flex justify-content-center">
                  {vaultData?.metadata_mla1&&(
                    vaultData.metadata_mla1.map((e,i) => {
                      return(
                        <div className="col-md-4 text-center mt-3" key={i}>
                          <img src={e.normalized_metadata?.image} className="img-fluid" alt="field agents"></img>
                          <h5 className="mt-2">{e.name + "#" + e.token_id}</h5>
                        </div>
                      )
                    })
                  )}
                </div>
                <div className="row d-flex justify-content-center mt-5">
                  {vaultData?.metadata_mla2&&(
                    vaultData.metadata_mla2.map((e,i) => {
                      return(
                        <div className="col-md-4 col-sm-6 text-center mt-3" key={i}>
                          <img src={e.normalized_metadata?.image} alt="secret agents" className="img-fluid"></img>
                          <h5 className="mt-2">{e.name + "#" + e.token_id}</h5>
                        </div>
                      )
                    })
                  )}
                </div>
                {/* <div className="text-center">
                  <a className="btn btn-lg border-btn" style={{marginTop: "1%"}} href="https://raritysniper.com/meta-bounty-hunters" target="_blank" rel='noreferrer'>
                    <img src={rarity_icon} alt="rarity-sniper" style={{border: "0px"}}/> Search MBH Rarity
                  </a>
                </div> */}
              </div>
            )
          )}
          { account && ( dashboardLoading ? (
              <div className="d-flex align-items-center justify-content-center mt-5">
              <ReactLoading
                type="spin"
                color="#c17717"
                height={120}
                width={120}
              />
            </div>
            ):(
              userInfo && userInfo!=null && (
              <div className="container dashboard mt-5 pb-5">
                <h1 className="gradient-text section-title text-center">MLAGENT DASHBOARD</h1>
                <Tabs className="mt-3">
                  <TabList className="text-center p-0 dashboard-menu">
                    <Tab className="btn btn-border gradient">MY PROFILE</Tab>
                    <Tab className="btn btn-border gradient">MY AGENTS</Tab>
                    <Tab className="btn btn-border gradient">FREE REWARDS</Tab>
                    <Tab className="btn btn-border gradient">MY NFTS</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dashboard-content">
                        <p>Full Name: {userInfo.mdetails.username}</p>
                        <p>Call Sign: {userInfo.mdetails.username}</p>
                        <p>Referred Call Sign: {userInfo.mdetails.parentusername}</p>
                        <p>MetaMaskID: {userInfo.mdetails.metamaskid}</p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="d-flex align-items-center justify-content-center">
                      {
                        userInfo.squadron.length>0 ? (
                          <div className="dashboard-content">
                            {
                              userInfo.squadron.map((item,index) => {
                                return(
                                  <p key={index}>Level {item.level} Enlistment : {item.squadron.length} with {item.squadron.reduce((a, b) => a + (b['nfts'] || 0), 0)} NFTs</p>
                                );
                              })
                            }
                          </div>
                        ) : (
                          <div className="dashboard-content text-center">
                            NO AGENTS
                          </div>
                        )
                      }
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="text-center">
                      <Tabs>
                        <TabList className="dashboard-btn-area p-0 dashboard-submenu mt-5">
                          <Tab className="btn btn-border gradient">TOTAL</Tab>
                          <Tab className="btn btn-border gradient">CLAIMED</Tab>
                          <Tab className="btn btn-border gradient">CLAIMABLE</Tab>
                        </TabList>
                        <TabPanel>
                          <div className="dashboard-content" style={{display:"inline-block"}}>
                              <p>DIRECT FREE REWARDS : {directPayout} ETH</p>
                              <p>AFFILIATE FREE REWARDS : {affiliatePayout} ETH</p>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="dashboard-content" style={{display:"inline-block"}}>
                              <p>DIRECT FREE REWARDS : {directPaidAmount} ETH</p>
                              <p>AFFILIATE FREE REWARDS : {affiliatePaidAmount} ETH</p>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="dashboard-content" style={{display:"inline-block"}}>
                              <p>DIRECT FREE REWARDS : {(directPayout-directPaidAmount).toFixed(3)} ETH</p>
                              <p>AFFILIATE FREE REWARDS : {(affiliatePayout-affiliatePaidAmount).toFixed(3)} ETH</p>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dashboard-content">
                          <p>Total NFTs: {userInfo.mdetails.nfts}</p>
                          <p>MetaMaskID: {userInfo.mdetails.metamaskid}</p>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              )
            ))
          }
          {/* <div className="mt-3">
            <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            <div className="elfsight-app-fd7a0630-3123-4379-ac13-2096b3247517"></div>
          </div> */}
      </div>
    </div>
  );
};
