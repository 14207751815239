import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import button_icon from "../../assets/images/huntress_button_icon.png";
import header_img1 from "../../assets/images/huntress_verify_header.png";
import header_img2 from "../../assets/images/huntress_verify_header2.png";

export const HuntressVaultHeader = (props) => {
    const { metamaskConnected, account, setMetamaskConnnected, setDisclaimerModal } = props;

    const handleConnectWallet = async () => {
        try {
            setDisclaimerModal(true);
            if (window.ethereum) {
              await window.ethereum.enable();
              setMetamaskConnnected(true);
              window.location.reload();
            } else {
              window.open("https://metamask.io/", "_blank");
            }
          } catch (e) {
            console.log(e);
          }
    };

    return (
        <div id="verify-header">
            <div className="container">
                <motion.div
                    initial={{ opacity: 0, y: 200 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ type: "spring", stiffness: 30, delay: 0.5 }}
                    viewport={{ once: true, amount: 0 }}
                >
                    <div className="header-title">
                        <h1>HUNTRESS VAULT</h1>
                        <p><strong>Welcome Huntress!</strong></p>
                        <p>Connect the wallet that is holding your Meta Bounty Huntress NFT to view your collection, free rewards and team.</p>
                        <div className="button-box mt-5">
                            <div className="button-top-line"></div>
                            {metamaskConnected ? (
                                <HashLink smooth to="#main" className="button-shape common-btn blue-gradient">
                                    <div className="second-btn-bg"></div>
                                    <img src={button_icon} alt="opensea_icon" />
                                    <span>GET STARTED NOW</span>
                                </HashLink>
                            ) : (
                                <button className="button-shape common-btn green-gradient" onClick={handleConnectWallet}>
                                    <div className="second-btn-bg"></div>
                                    <img src={button_icon} alt="opensea_icon" />
                                    <span>CONNECT YOUR WALLET</span>
                                </button>
                            )}

                            <div className="button-bottom-line"></div>
                        </div>
                    </div>
                </motion.div>
            </div>
            <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, delay: 0.7 }}
                viewport={{ once: true, amount: 0 }}
                className="verify-header-img1"
            >
                <img src={header_img1} alt="header asset" />
            </motion.div>
            <motion.div
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, delay: 0.7 }}
                viewport={{ once: true, amount: 0 }}
                className="verify-header-img2"
            >
                <img src={header_img2} alt="header asset" />
            </motion.div>
        </div>
    )
}
